import { Typography } from "@mui/material";
import compImages from "../Images/companiesImg.svg"

const CompaniesAndSocietyComp = () => {
    return (
        <div style={{margin: '30px 7% 80px 7%'}}>
            <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginTop: '48px'}}>Participating companies and societies</Typography>
            <Typography sx={{fontSize: '20px', marginTop: '20px', marginBottom: '20px'}}>Neuroscience & Psychiatry 2024 extends its gratitude to all industry companies and societies
            participating in the conference. These companies are engaged in various activities, including exhibition stands, symposia, and more. We encourage all conference delegates to explore the latest technologies, products, and techniques
            showcased by these industry partners. Together, we are committed to advancing patient safety and promoting mental health!
            </Typography>

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <img style={{width: '100%', maxWidth: '100%'}} src={compImages}/>
            </div>
        </div>
    )
}

export default CompaniesAndSocietyComp;