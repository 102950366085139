import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import "@fontsource/dm-sans"; // Defaults to weight 400
import "@fontsource/dm-sans/400.css"; // Specify weight
import "@fontsource/dm-sans/400-italic.css"; // Specify weight and style
import logo from '../Images/logo.svg'
import { Fab, Menu, MenuItem, Popover, Stack, styled, useMediaQuery } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useRef, useState } from 'react';
import "./DrawerAppBar.css"
import { Link, useNavigate } from 'react-router-dom';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
// import rightupArrow from "../Images/right-up.png";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  page_name?: string;
}

const drawerWidth = 240;


const navItems = [
  {
    main_nav_item : 'Home',
    sub_nav_item : []
  },
  {
    main_nav_item : 'Industry & Exhibition',
    sub_nav_item : [{
      page_name: 'About Industry',
      page_path: '/industryexhibition'
    },
    {
      page_name: 'Companies & Societies',
      page_path: '/companiesandsociety'
    },
    {
      page_name: 'Scientific Sessions',
      page_path: '/scientificsessions'
    }]
  },
  {
    main_nav_item : 'Congress Information',
    sub_nav_item : [{
      page_name: 'About Us',
      page_path: '/aboutus'
    },
    {
      page_name: 'Esteemed Commitee',
      page_path: '/esteemedcommittee'
    },
    {
      page_name: 'Congress FAQ',
      page_path: '/congressfaq'
    },
    {
      page_name: 'Accommodation',
      page_path: '/accomodation'
    },
    {
      page_name: 'Venue',
      page_path: '/venue'
    }]
  },
  {
    main_nav_item : 'Register',
    sub_nav_item : [{
      page_name: 'Abstract Submission',
      page_path: '/abstractsubmission'
    },
    {
      page_name: 'Registration',
      page_path: '/register'
    }]
  },
]

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function DrawerAppBar(props: Props) {

  const { window, page_name = ""  } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const container = window !== undefined ? () => window().document.body : undefined;
  const isMobile = useMediaQuery('(max-width:1035px)');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigate("/")
  };

  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      event.stopPropagation();
      setExpanded(newExpanded ? panel : false);
    };

    const handleClickInsideAccordion = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      // Add any other handling logic here if needed
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box sx={{ backgroundColor: '#082932', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={logo} alt="Logo" style={{ display: 'inline-block' }} />
        <Typography variant="h6" sx={{ color: 'white', display: 'inline-block', marginLeft: '10px', fontFamily: 'DM Sans, sans-serif' }}>
          NSSA
        </Typography>
      </Box>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          index === 0 ? (
              <div style={{display: 'flex', marginLeft: "17%"}}>
                <Link style={{textDecoration: 'none', paddingBottom: '10px', color: 'inherit'}} to="/">{item.main_nav_item}</Link>
              </div>
          ) : (
            <Accordion sx={{border: '2px solid white'}} expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
                 <AccordionSummary  sx={{backgroundColor: 'white'}} aria-controls="panel1d-content" id={`panel${index+1}d-header`}>
                   <Typography>{item.main_nav_item}</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                   {item.sub_nav_item.map((item, index) => (
                    <div style={{display: 'flex', marginLeft: '15%'}}>
                      <Link style={{textDecoration: 'none', paddingBottom: '15px', color: 'inherit'}} to={item.page_path}>{item.page_name}</Link>
                    </div>
                   ))}
                 </AccordionDetails>
               </Accordion>
          )
        ))}
      </List>
    </Box>
    );
  

  return (
    <Box sx={{ display: 'flex', overflowX: 'clip', background: page_name == 'home' ? 'linear-gradient(to bottom, #00171D, #06242B)' : '#082932', height: '14vh'}}>
      <CssBaseline />
      <AppBar elevation={0} component="nav" sx={{background: page_name == 'home' ? 'linear-gradient(to bottom, #00171D, #06242B)' : '#082932', position: 'initial'}}>
        <Toolbar sx={{marginTop: 'auto', marginBottom: 'auto', marginLeft: isMobile ? 0 : '70px', marginRight: isMobile ? 0 : '70px'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="Logo" style={{ marginRight: '16px' }} />
          <Typography variant="h6" component="div" sx={{
              flexGrow: 1, 
              fontSize: 18.38,
              display: { xs: 'block', md: 'block' },
              fontFamily: 'DM Sans, sans-serif',
              fontWeight: 700 // Adjust font weight for bold effect
            }}>
            Neurosurgical Society <br/>
            of America
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'block' }, marginLeft: '100px' }}>
            {navItems.map((item, index) => (
              <React.Fragment>
                {
                  index === navItems.length - 1 ? (
                      <Fab 
                      variant="extended" 
                      className='navigation'
                      onClick={index === 0 ? handleClick : undefined}
                      key={item.main_nav_item}
                      sx={{
                          backgroundColor: 'transparent',
                          color: '#FFFFFF',
                          border: '1px solid #FFFFFF',
                          marginLeft: '20px',
                          textTransform: 'none',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          paddingLeft: '20px',
                          paddingRight: '20px',
                          '&:hover': {
                              backgroundColor: 'transparent',
                              color: '#FFFFFF'
                          },
                          '&:active': {
                              backgroundColor: 'transparent',
                              color: '#FFFFFF'
                          }
                      }}
                      >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                      {item.main_nav_item}
                    </div>
                    {
                      index == navItems.length - 1 ? (
                        <>
                        </>
                      ) : 
                      (
                        <>
                          {/* <img style={{filter: 'invert(1)'}} width={30} height={30} src={rightupArrow}/> */}
                        </>
                      )
                    }
                    
                  </div>
                  <div className='navigation-content-register'>
                    {item.sub_nav_item.map((item) => (
                     <div>
                      <Link to={item.page_path} className='nav-link'>{item.page_name}</Link>
                     </div>
                    ))}
                  </div>
                      </Fab>
                  ) : (
                    <Button
                  className='navigation'
                  onClick={index === 0 ? handleClick : undefined}
                  key={item.main_nav_item}
                  sx={{
                    color: '#fff',
                    textTransform: 'capitalize',
                    fontSize: 17,
                    marginLeft: '30px',
                    position: 'relative',
                  }}
                >
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                      {item.main_nav_item}
                    </div>
                    {
                      index == navItems.length - 1 ? (
                        <>
                        </>
                      ) : 
                      (
                        <>
                          {/* <img style={{filter: 'invert(1)'}} width={30} height={30} src={rightupArrow}/> */}
                        </>
                      )
                    }
                    
                  </div>
                  <div className='navigation-content'>
                    {item.sub_nav_item.map((item) => (
                     <div>
                      <Link to={item.page_path} className='nav-link'>{item.page_name}</Link>
                     </div>
                    ))}
                  </div>
                </Button>
                  )
                }
                
              </React.Fragment>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: isMobile ? 2 : 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}