import { Backdrop, Box, Fade, Grid, Modal, Typography, useMediaQuery } from "@mui/material";
import facebooklogo from "../Images/facebook-black.svg";
import twitterlogo from "../Images/twitter-black.svg";
import vimeologo from "../Images/vimeo-black.svg";
import youtubelogo from "../Images/youtube-black.svg";
import logodark from "../Images/logodark.svg";
import { useState } from "react";
import { Height } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    overflow: 'auto',
    bgcolor: 'white',
    borderRadius : '20px',
    boxShadow: 15,
    p: 4,
  };

const Footer = () => {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleOpen2 = () => setOpen2(true);
    const handleOpen3 = () => setOpen3(true);
    const handleClose = () => setOpen(false);
    const handleClose2 = () => setOpen2(false);
    const handleClose3 = () => setOpen3(false);
    const navigate = useNavigate();

    const handleFooterClicked = (value: string) => {
        if(value == "aboutus"){
            navigate("/aboutus");
        }
        else if (value == "sessions"){
            navigate("/scientificsessions")
        }
        else if (value == "register"){
            navigate("/register")
        }
    }

    const isMobile = useMediaQuery('(max-width:1035px)');
    return (
        <div style={{margin: '70px auto 100px auto', padding: '0 20px 0 20px', maxWidth: '1300px'}}>
            <Grid container spacing={1}>
                <Grid xs={12} md={6}>
                    <div style={{marginBottom: isMobile ? '60px' : '', display: 'flex', flexDirection: 'row', justifyContent: isMobile ? 'space-evenly' : ''}}>
                        <div onClick={() => handleFooterClicked('aboutus')} style={{marginRight: isMobile ? '0' : '6%', cursor: 'pointer'}}>
                            <Typography sx={{color: '#0A142F', fontSize: '16px'}}>About Us</Typography>
                        </div>
                        <div onClick={() => handleFooterClicked('sessions')} style={{marginRight: isMobile ? '0' : '6%',cursor: 'pointer'}}>
                            <Typography sx={{ color: '#0A142F', fontSize: '16px'}}>Sessions</Typography>
                        </div>
                        <div onClick={() => handleFooterClicked('register')} style={{marginRight: isMobile ? '0' : '6%',cursor: 'pointer'}}>
                            <Typography sx={{ color: '#0A142F', fontSize: '16px'}}>Register</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} md={6}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: isMobile ? 'space-evenly' : 'end'}}>
                            <img style={{paddingRight: isMobile ? '0' : '10%'}} src={facebooklogo}/>
                            <img style={{paddingRight: isMobile ? '0' : '10%'}} src={twitterlogo}/>
                            <img style={{paddingRight: isMobile ? '0' : '10%'}} src={vimeologo}/>
                            <img src={youtubelogo}/>
                        </div>
                </Grid>
                <Grid xs={12}>
                    <hr style={{margin:'50px 0 50px 0' ,color: '#2B3D51'}}/>
                </Grid>
                <Grid xs={12} md={4}>
                    <div style={{display: 'flex',flexDirection: "column", alignItems: isMobile ? 'center' : '' , justifyContent: isMobile ? 'center' : 'felx-start', marginBottom: isMobile ? '20px' : '0'}}>
                        <Typography sx={{color: '#0A142F', fontSize: '14px'}}>Organized by Mednode Media</Typography>
                        <Typography sx={{color: '#0A142F', fontSize: '14px'}}>© Mednode Media 2024 </Typography>
                        <Typography sx={{color: '#0A142F', fontSize: '14px'}}>All Rights Reserved</Typography>
                    </div>
                </Grid>
                <Grid xs={12} md={4}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: isMobile ? '20px' : '0'}}>
                        <img src={logodark} alt="Logo" style={{ display: 'inline-block' }} />
                        <Typography variant="h6" sx={{ color: '#174B58',fontWeight:'bold', display: 'inline-block', marginLeft: '10px', fontFamily: 'DM Sans, sans-serif' }}>
                        NSSA
                        </Typography>
                    </div>
                </Grid>
                <Grid xs={12} md={4}>
                    <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: isMobile ? '' : 'end', alignItems: isMobile ? 'center' : ''}}>
                        <div onClick={handleOpen} style={{cursor: 'pointer', marginBottom: isMobile ? '20px' : '0'}}>
                            <Typography sx={{color: '#0A142F',marginRight: isMobile ? '0' : '40px', fontSize: '14px'}}>Terms of Service</Typography>
                        </div>
                        <div onClick={handleOpen2} style={{cursor: 'pointer', marginBottom: isMobile ? '20px' : '0', marginRight: isMobile ? '0' : '40px'}}>
                            <Typography sx={{color: '#0A142F', fontSize: '14px'}}>Contact Us</Typography>
                        </div>
                        <div onClick={handleOpen3} style={{cursor: 'pointer', marginBottom: isMobile ? '20px' : '0'}}>
                            <Typography sx={{color: '#0A142F', fontSize: '14px'}}>Privacy Policy</Typography>
                        </div>
                    </div>
                </Grid>
                
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 500,
                },
                }}>
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" sx={{color: '#174B58', fontWeight: 'bold', fontSize: '36px'}}>
                            Terms and Conditions
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            Responsibility
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                            All attendees are solely responsible for their valuable personal belongings during the Neuroscience
                            & Psychiatry 2024 conference. The organizers do not hold any responsibility for any missing or stolen
                            materials for any reason.
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            Request for Invitation Letter
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                            Letters of invitation will only be sent via mail to registered participants.
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            Registration Transfer
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                            All fully paid registrations are eligible for transfer to another person interested in attending the same
                            conference. Requests for registration transfer must be made formally in writing to the concerned
                            Program Manager within 30 days from the date of registration. After receiving the request, the
                            Program Manager will provide consent for processing the transfer, and subsequent necessary
                            actions will be taken. This option is available only when the registered participant is unable to attend
                            the event. Refunds for transferred registrations are not permissible
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            VISA Guidelines
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                            Due to international security reasons, it is strongly recommended to apply for a VISA as soon as
                            registration for the conference is confirmed. The Neuroscience &amp; Psychiatry 2024 conference
                            organizers will not directly engage with any embassy or consulate regarding VISA applications.
                            However, assistance will be provided to navigate through the process. Any VISA issues hindering an
                            attendee’s participation cannot be considered under the cancellation policy of Neuroscience & Psychiatry 2024.
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            Insurance
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                            Any form of insurance is not covered under the registration fee paid by attendees.
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            Transportation
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                            All transportation arrangements are solely the responsibility of the participants.
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            Media/Press
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                            Prior permission must be obtained from the organizers for allowing media inside the conference venue, as it is generally restricted. As a non-profit organization, commercialization of the event is not encouraged.
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, textDecoration: 'underline', fontWeight: 'bold', fontSize: '22px' }}>
                            Refund and Cancellation Policy
                        </Typography>
                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            Refund Policy
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                            In the event that a registered participant is unable to attend the Neuroscience & Psychiatry 2024 conference physically due to unforeseen circumstances, the following refund policy applies:
                            <ul style={{marginLeft: '20px', marginTop: '20px'}}>
                                <li>Before 50 days of the conference: Eligible for a refund of 50% of the registered fee</li>
                                <li>Before 30 days of the conference: Not eligible for any refund.</li>
                                <li>Note: A 20% service charge will be deducted from the registration fee, and no refund is applicable for accommodation fees during the refund process.</li>
                                <li>Within 7 working days, the refund amount will be transferred.</li>
                            </ul>
                            <Typography sx={{mt: 2, fontSize: '20px', fontStyle: 'italic'}}>
                                <span style={{fontWeight: 'bold'}}>Note: </span>A 20% service charge will be deducted from the registration fee, and no refund is applicable for accommodation fees during the refund process.
                            </Typography>
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            Accommodation Cancellation Policy
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                            Once accommodation confirmation is received through registration, it cannot be revoked under any circumstances, although modifications may be permitted to a certain extent.
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            Cancellation/Postponement Policy
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                            Any changes (modification, cancellation, or transfer of registration) in the participant’s attendance must be duly communicated to the Program Manager. In the event that Neuroscience & Psychiatry 2024 is canceled or postponed for any unforeseen reason, all registered attendees will receive a 100% reimbursement of their registration fee paid. The credit amount can be used to register for any other Neuroscience & Psychiatry event happening within one year from the announcement of cancellation or postponement. The refund amount will be transferred within 7 working days.                        
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            Limitation of Liability
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                            Mednode Media PVT LTD, as the organizer of the Neuroscience & Psychiatry 2024 conference, shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to participation in the conference. This includes, but is not limited to, any issues related to travel arrangements, accommodation, or personal property. The total liability of Mednode Media PVT LTD, whether in contract, warranty, tort (including negligence), or otherwise, shall not exceed the amount of the registration fee paid by the participant.                        
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={handleClose2}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 500,
                },
                }}>
                <Fade in={open2}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" sx={{color: '#174B58', fontWeight: 'bold', fontSize: '36px'}}>
                            Contact Us
                        </Typography>

                        
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                        Merchant Legal entity name: MEDNODE MEDIA PRIVATE LIMITED
                        Registered Address: NO 27, LAKSHMI NAGAR EXTN, SDNB VAISHNAVA COLLEGE,
                        CHROMEPET, CHENNAI, KANCHEEPURAM, TAMIL NADU, 600044, CHROMEPET, Tamil Nadu,
                        PIN: 600044
                        Operational Address: NO 27, LAKSHMI NAGAR EXTN, SDNB VAISHNAVA COLLEGE,
                        CHROMEPET, CHENNAI, KANCHEEPURAM, TAMIL NADU, 600044, CHROMEPET, Tamil Nadu,
                        PIN: 600044
                        Telephone No: 9952050554
                        E-Mail ID: mednodemedia@gmail.com
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open3}
                onClose={handleClose3}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 500,
                },
                }}>
                <Fade in={open3}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" sx={{color: '#174B58', fontWeight: 'bold', fontSize: '36px'}}>
                            Privacy Policy
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                            Introduction
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                        We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains what information we collect, how we use it, and the steps we take to ensure your data is protected.
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                        Information We Collect
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                        We collect the following personal information from you when you register for our conference:
                        First Name,
                        Last Name,
                        Email Address,
                        Phone Number,
                        Address                 
                        </Typography>
                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                        How We Use Your Information
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                        The information we collect is used solely for the purpose of:

                        Communicating with you regarding the conference, including updates, reminders, and other relevant information.
                        Providing customer support and responding to your inquiries.                        
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                        Sharing Your Information
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                        We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website or organizing the conference, as long as those parties agree to keep this information confidential.                       
                        </Typography>

                        <Typography id="transition-modal-description" sx={{color: '#174B58', mt: 2, fontWeight: 'bold', fontSize: '22px' }}>
                        Your Consent
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '20px' }}>
                        By using our site and registering for the conference, you consent to our website’s privacy policy.                       
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default Footer;