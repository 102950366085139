import AccomodationComp from "../../Components/AccomodationComp";
import DrawerAppBar from "../../Components/DrawerAppBar";
import Footer from "../../Components/Footer";
import TimerCard from "../../Components/TimerCard";

const AccomodationPage = () => {
    return (
        <div>
            <DrawerAppBar/>
            <AccomodationComp/>
            <TimerCard/>
            <Footer/>
        </div>
    )
}

export default AccomodationPage;