import { Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import brainfactsimg from "../Images/brainfacts.svg";
import eneuroimg from "../Images/eneuro.svg";
import jneuro from "../Images/jneuro.svg";
import sfnimg from "../Images/sfn.svg";
import neurolineimg from "../Images/neuroonline.svg";
import ssfnimg from "../Images/ssfn.svg";
import "./CollaboratorsMarquee.css"

const CollaboratorsMarquee = () => {
    return (
        <div style={{margin: "100px 0px 0px 0px"}}>
            <Marquee>
                    <img className="collablogo" src={brainfactsimg}/>
                    <img className="collablogo" src={eneuroimg}/>
                    <img className="collablogo" src={jneuro}/>
                    <img className="collablogo" src={neurolineimg}/>
                    <img className="collablogo" src={sfnimg}/>
                    <img className="collablogo" src={ssfnimg}/>
            </Marquee>
       </div>
    )
}

export default CollaboratorsMarquee;