import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Fab } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function PopupPage() {
  const [open, setOpen] = React.useState(true);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setOpen(true);
  //   }, 3000);
  // }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setOpenn(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openn, setOpenn] = React.useState(false);

  const handleCLickOpenn = () => {
    setOpenn(true);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        className="Modal"
        aria-labelledby="customized-dialog-title"
        id="popup-modal"
        open={open}
      >
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}
        >
          <Typography
            gutterBottom
            sx={{ fontSize: "20px", fontWeight: "bold", margin: "20px 0" }}
          >
            This site is intended for Healthcare professionals only.
          </Typography>
          <Typography sx={{ fontSize: "17px", fontWeight: "medium" }}>
            Neuroscience & Psychiatry Conference 2024
          </Typography>
          <Typography gutterBottom sx={{ fontSize: "16px" }}>
            21-22 May at ExCeL DubaiThe
          </Typography>
          <Typography gutterBottom sx={{ margin: "20px 0" }}>
            The Neuroscience & Psychiatry Conference is a must-attend,
            multi-disciplinary event delivering free CPD-certified learning for
            the entire neuroscience and psychiatry community.
          </Typography>
          <Typography
            gutterBottom
            sx={{ fontSize: "16px", fontWeight: "bold", marginBottom: "20px" }}
          >
            Are you a Healthcare professional?
          </Typography>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Fab
              variant="extended"
              onClick={handleClose}
              sx={{
                backgroundColor: "#174B58",
                color: "#FFFFFF",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "bold",
                paddingLeft: "51px",
                paddingRight: "51px",
                paddingTop: "18px",
                paddingBottom: "18px",
                "&:hover": {
                  backgroundColor: "#174B58",
                  color: "#FFFFFF",
                },
                "&:active": {
                  backgroundColor: "#174B58",
                  color: "#FFFFFF",
                },
              }}
            >
              Yes
            </Fab>
            <Fab
              variant="extended"
              onClick={handleCLickOpenn}
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#143F49",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "bold",
                paddingLeft: "51px",
                paddingRight: "51px", 
                paddingTop: "18px",
                paddingBottom: "18px",
              }}
            >
              No
            </Fab>
          </Stack>
          {/* <Button autoFocus onClick={handleClose}>
              Yes
            </Button>
            <Button autoFocus >
              No
            </Button> */}
        </DialogContent>
        {/* <DialogActions sx={{justifyContent: 'center'}}>
        <Button autoFocus onClick={handleClose}>
              Yes
            </Button>
            <Button autoFocus >
              No
            </Button>
        </DialogActions> */}
      </BootstrapDialog>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={openn}>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}
        >
          <Typography
            gutterBottom
            sx={{ fontSize: "20px", fontWeight: "bold", margin: "20px 0" }}
          >
            Sorry! This site is for Healthcare professionals only.
          </Typography>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Fab
              variant="extended"
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#174B58",
                color: "#FFFFFF",
                border: "1px solid #FFFFFF",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "bold",
                paddingLeft: "51px",
                paddingRight: "51px",
                paddingTop: "18px",
                paddingBottom: "18px",
                "&:hover": {
                  backgroundColor: "#174B58",
                  color: "#FFFFFF",
                },
                "&:active": {
                  backgroundColor: "#174B58",
                  color: "#FFFFFF",
                },
              }}
            >
              Back
            </Fab>
          </Stack>
          {/* <Button autoFocus onClick={handleClose}>
              Yes
            </Button>
            <Button autoFocus >
              No
            </Button> */}
        </DialogContent>
        {/* <DialogActions sx={{justifyContent: 'center'}}>
        <Button autoFocus onClick={handleClose}>
              Yes
            </Button>
            <Button autoFocus >
              No
            </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </React.Fragment>
  );
}
