import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider, Grid, MenuItem, Paper, Stack, TextField, styled, useMediaQuery } from '@mui/material';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import withStyles from '@material-ui/core/styles/withStyles';
import completedImage from "../Images/completedImage.svg";
import {cashfree} from "./Util.js"
import './GlobalStyling.css'
import { useCallback, useEffect, useRef, useState } from 'react';
import Lottie from "react-lottie";
import animationData from "../Lottie/loader_lotty.json"
import { Visibility } from '@mui/icons-material';
import AWS from 'aws-sdk'
import "./RegistrationStepper.css"

const steps = ['', '', ''];
interface FormValues {
  [key: string]: any;
}

interface RegistrationDetails {
  type : string,
  amount : number
}

const s3 = new AWS.S3({
  accessKeyId: "AKIAVRUVSYJT4DF4G3GT",
  secretAccessKey: "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",
  region: "eu-north-1"
});

// const client = new MongoClient("mongodb+srv://mednodemedia:aXowCwDeb8FlgfQx@mednode.hcucolp.mongodb.net/?retryWrites=true&w=majority&appName=mednode");

const uploadFileToS3 = async (file: File) => {
  const params: any = {
    Bucket: "registrationpdf",
    Key: `abstracts/${Date.now()}_${file.name}`,
    Body: file,
    ContentType: file.type
  };

  try {
    const data = await s3.upload(params).promise();
    return data.Location; // Return the URL of the uploaded file
  } catch (error) {
    console.error('Error uploading file to S3:', error);
    throw error;
  }
};

// Example country to currency symbol mapping
const currencySymbols : any = {
  'unitedstates': '$',
  'india': 'INR',
  'france': '€',
  'england': '£'
  // Add other mappings as needed
};

export default function RegistrationStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [selectedItem, setSelectedItem] = React.useState<number>(0);
  const isMobile = useMediaQuery('(max-width:1035px)');
  const formRefs = useRef<FormValues>({formsType: 'registration'});
  const [finalValues, setFinalValues] = useState<FormValues>({});
  const [step1DisplayType, setStep1DisplayType] = useState<string>('block');
  const [step2DisplayType, setStep2DisplayType] = useState<string>('none');
  const [step3DisplayType, setStep3DisplayType] = useState<string>('none');
  const [loaderVisibility, setLoaderVisibility] = useState<string>('none')
  const [registrationDetails, setRegistrationDetails] = React.useState<RegistrationDetails | null>(null);
  const [sessionId, setSessionId] = useState("")

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    console.log("hello")
    console.log({...formRefs.current})
    // Check if all required fields are filled
    if (activeStep + 1 === 1) {
      const requiredFields = ['firstName', 'lastName', 'phoneNumber', 'emailAddress', 'country', 'address'];
      for (let field of requiredFields) {
        if (!formRefs.current[field]) {
          alert(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
          return; // Prevent advancing to the next step
        }
      }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number without any spaces or special characters

    const emailValid = emailRegex.test(formRefs.current.emailAddress);
    const phoneValid = phoneRegex.test(formRefs.current.phoneNumber);

    if (activeStep === 0) {
      if (!emailValid) {
        alert("Please enter a valid email address.");
        return;
      }
      if (!phoneValid) {
        alert("Please enter a valid 10-digit phone number.");
        return;
      }
    }
  }
    setFinalValues({ ...formRefs.current });
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    if(activeStep + 1 === 1){
      setStep1DisplayType('none')
      setStep2DisplayType('block')
      setStep3DisplayType('none')
    }
    else if(activeStep + 1 === 2){
      setLoaderVisibility("block")
      setStep2DisplayType("none")
      const abstractFile: File = formRefs.current.abstract;
      //const abstractFileUrl = await uploadFileToS3(abstractFile);
      // Update finalValues with the S3 URL
      const updatedFinalValues: FormValues = { ...formRefs.current, abstract: "abstractFileUrl" };
      // Combine finalValues and registrationDetails
      const combinedData = { ...updatedFinalValues, ...registrationDetails };
      console.log(combinedData);

      // invoke lambda function for inserting the funtion to the database.
      // Call the Lambda function via API Gateway
      const response = await fetch('https://jbgyp7s33h3fx6nlmavrhic35y0zklpl.lambda-url.eu-north-1.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(combinedData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Data stored successfully:', result);

      const myHeaders = new Headers();
      let currency = "";
      if(finalValues.country == 'india'){
        currency = 'INR'
      }
      else if (finalValues.country == 'unitedstates'){
        currency = "USD"
      }
      else if (finalValues.country == 'france'){
        currency = "EUR"
      }
      else{
        currency = "GBP"
      }
      myHeaders.append("x-api-version", "2023-08-01");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");

      const raw = JSON.stringify({
        "order_amount": registrationDetails?.amount,
        "order_currency": currency,
        "customer_details": {
          "customer_id": finalValues.phoneNumber,
          "customer_name": finalValues.firstName + " " + finalValues.lastName,
          "customer_email": finalValues.emailAddress,
          "customer_phone": finalValues.phoneNumber,
        },
        "order_meta": {
          "return_url": "https://b8af79f41056.eu.ngrok.io?order_id=order_123",
          "notify_url": "https://webhook.site/442e52fe-dc8e-4115-b455-8aef5abaec30"
        }
      });

      const requestOptions : any = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("https://txifgcbxlpkwfwaawkeq2lf3gi0picjn.lambda-url.eu-north-1.on.aws/", requestOptions)
      .then((response) => {
          if (!response.ok) {
              throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json(); // Parse the response as JSON
      })
      .then((result) => {
          console.log(result);

          if (result.payment_session_id) {
              let checkoutOptions = {
                  paymentSessionId: result.payment_session_id,
                  redirectTarget: "_modal",
              };

              cashfree.checkout(checkoutOptions).then((result: any) => {
                  if (result.error) {
                      // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
                      console.log("User has closed the popup or there is some payment error, Check for Payment Status");
                      console.log(result.error);
                  }
                  if (result.redirect) {
                      console.log("Payment will be redirected");
                  }
                  if (result.paymentDetails) {
                      // This will be called whenever the payment is completed irrespective of transaction status
                      setStep1DisplayType('none')
                      setStep2DisplayType('none')
                      setLoaderVisibility('none')
                      setStep3DisplayType('block')
                      console.log("Payment has been completed, Check for Payment Status");
                      console.log(result.paymentDetails.paymentMessage);
                  }
              });
          } else {
              console.error("Payment session ID is missing in the response");
          }
      })
      .catch((error) => console.error('Fetch error:', error));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if(activeStep - 1 === 0){
      setStep1DisplayType('block')
      setStep2DisplayType('none')
    }
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    formRefs.current[name] = value;
  };

  const countries = [
    {
      value: 'india',
      label: 'India',
    },
    {
      value: 'unitedstates',
      label: 'United States',
    },
    {
      value: 'england',
      label: 'England',
    },
    {
      value: 'france',
      label: 'France',
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#E7E7E7",
    ...theme.typography.body2,
    borderRadius: '16px',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '55px 50px 55px 50px',
    justifyContent: 'space-around'
  }));

  const handleItemClick = (index: number, type: string, amount: any) => {
    setSelectedItem(index);
    setRegistrationDetails({ type, amount });
  };

    const hiddenFileInput: any = useRef(null);
    const [file, setFile] = useState<File>();
    const [fileVisibility, setFileVisibility] = useState("none");
    const [fileVisibility2, setFileVisibility2] = useState("block");

    const handleClick = () => {
       hiddenFileInput.current.click();
    };

    const handleChangeFileUpload = (event : any) : any => {
        const selectedFile = event.target.files[0];
        const reader: any = new FileReader();
        setFile(reader.result); 
        setFileVisibility("block")
        setFileVisibility2("none")
        formRefs.current["abstract"] = event.target.files[0];
        console.log(selectedFile)
    }

  return (
    <Grid container rowSpacing={2} columns={{ xs: 12 }} sx={{ marginTop: '30px' }} alignItems="center" justifyContent="center">
        <Grid sx={{marginBottom: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center'}} xs={12} md={12}>
            <Typography sx={{fontWeight: 'bold', fontSize: '34px'}}>Register Your Slot</Typography>
            <Typography sx={{fontSize: '16px'}}>Please fill the form below to book your slot for the conference.</Typography>
        </Grid>
        <Box
            sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: isMobile? '80%' : '55%',
            margin: 'auto',
            boxShadow: 4, // Adjust shadow intensity as needed
            borderRadius: 8, // Add border radius for a softer look
            p: isMobile?  4 : 7,
            paddingBottom: '50px', // Add padding for better separation from the box
            }}>
                <Box sx={{ width: '100%' }}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Stepper sx={{width: isMobile? '100%' : '50%'}} activeStep={activeStep}>
                            {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step sx={{
                                    '& .MuiStepLabel-root .Mui-completed': {
                                      color: '#174B58', // circle color (COMPLETED)
                                    },
                                    '& .MuiStepLabel-root .Mui-active': {
                                      color: '#174B58', // circle color (ACTIVE)
                                    },
                                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                    {
                                        color: '#EFF0F6', // Just text label (ACTIVE)
                                    },
                                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                      fill: '#FFFFFF', // circle's number (ACTIVE)
                                    },
                                    '& .MuiStepLabel-root .MuiStepIcon-text': {
                                        fill: '#000000', // circle's number (ACTIVE)
                                    },
                                    '& .MuiStepIcon-root': {
                                        color: index !== activeStep ? '#EFF0F6' : undefined, // Change background color for non-active steps' circles
                                      },
                                  }} key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                            })}
                        </Stepper>
                    </div>
                    
                    <Divider sx={{margin: '34px 0 34px 0'}}/>
                    <div style={{display: loaderVisibility}}>
                      <Lottie 
                        options={defaultOptions}
                        style={{display: ''}}
                        height={100}
                        width={100}
                      />
                      <Typography sx={{display: 'flex', justifyContent: 'center'}}>Please Wait...</Typography>
                    </div>
                        <div style={{display: `${step3DisplayType}`}}>
                          <React.Fragment>
                          <Box sx={{padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                              <img src={completedImage} width={158} height={144}></img>
                              <Typography variant="h6" sx={{fontWeight: "bold", marginTop: '16px', marginBottom: '16px'}}>Booking Confirmed</Typography>
                              <Typography>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit corrupti veritatis deserunt eligendi reprehenderit consectetur, similique quaerat consequuntur. Saepe recusandae, laboriosam cumque fugit dolores sit? Suscipit, maxime rem! Minima, ratione?</Typography>
                          </Box>
                          </React.Fragment>
                        </div>

                        <React.Fragment>
                                <div style={{display: `${step1DisplayType}`}}>
                                    <Grid container columnSpacing={10} rowSpacing={5}>
                                        <Grid item xs={12} md={12}>
                                            <Typography sx={{fontSize: '26px', fontWeight: 'bold', marginBottom: '8px'}}>Contact Details</Typography>
                                            <Typography sx={{fontSize: '14px', color: '#646464'}}>Please fill your information so we can get in touch with you.</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                            id="outlined-required"
                                            label="First Name"
                                            name='firstName'
                                            helperText=""
                                            fullWidth
                                            InputProps={{ sx: { borderRadius: '9px' } }}
                                            sx={{
                                                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                                                borderRadius: '9px',
                                            }}
                                            onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="outlined-required"
                                                label="Last Name"
                                                name='lastName'
                                                helperText=""
                                                fullWidth
                                                InputProps={{ sx: { borderRadius: '9px' } }}
                                                sx={{
                                                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                                                borderRadius: '9px',
                                                }}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="outlined-required"
                                                label="Phone Number"
                                                name='phoneNumber'
                                                type='number'
                                                helperText=""
                                                fullWidth
                                                InputProps={{ sx: { borderRadius: '9px' } }}
                                                sx={{
                                                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                                                borderRadius: '9px',
                                                }}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="outlined-required"
                                                label="Email Address"
                                                name='emailAddress'
                                                helperText=""
                                                fullWidth
                                                InputProps={{ sx: { borderRadius: '9px' } }}
                                                sx={{
                                                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                                                borderRadius: '9px',
                                                }}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="outlined-required"
                                                label="Country"
                                                select
                                                defaultValue="select"
                                                helperText=""
                                                fullWidth
                                                InputProps={{ sx: { borderRadius: '9px' } }}
                                                name='country'
                                                onChange={handleChange}
                                                sx={{
                                                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                                                borderRadius: '9px',
                                                }}>
                                                {countries.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                                </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="outlined-required"
                                                label="Address"
                                                name='address'
                                                helperText=""
                                                fullWidth
                                                InputProps={{ sx: { borderRadius: '9px' } }}
                                                sx={{
                                                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                                                borderRadius: '9px',
                                                }}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                </div>
                                <div style={{display: `${step2DisplayType}`}}>
                                    <Box>
                                        <Stack spacing={3} direction={'column'}>
                                            {
                                                data.map((item, index) => (
                                                    <Item key={index}
                                                    onClick={() => handleItemClick(index, item.eventType, item.eventPrice[finalValues.country.toLocaleLowerCase()])}
                                                    sx={{
                                                      width: '100%',
                                                      backgroundColor: selectedItem === index ? '#174B58' : '#E7E7E7', // Change background color when clicked
                                                      display: 'flex', // Use flexbox
                                                      flexDirection: isMobile ? 'column' : 'row', // Stack vertically when isMobile is true
                                                      alignItems: 'center', // Align items vertically
                                                      justifyContent: 'space-between', // Distribute items evenly
                                                  }}>
                                                        <Box sx={{flex : 1}}>
                                                            <Typography sx={{fontWeight: 'bold', fontSize: '30.83px', color: selectedItem === index ? '#FFFFFF' : '#3D3D3D', paddingBottom: '15px'}}>
                                                                {item.eventType}
                                                            </Typography>
                                                            <Typography sx={{color: selectedItem === index ? '#FFFFFF' : '#3D3D3D', fontSize: '16px'}}>
                                                                {item.eventDescription}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{}}>
                                                            <Typography sx={{fontFamily: 'Inter Bold', fontSize: '30px', color: selectedItem === index ? '#FFFFFF' : '#3D3D3D', textAlign: 'center'}}>
                                                              {currencySymbols[finalValues.country]}
                                                              </Typography>
                                                              <Typography style={{fontFamily: 'Inter Bold', fontSize: '60px', color: selectedItem === index ? '#FFFFFF' : '#3D3D3D', textAlign: 'center'}}>{item.eventPrice[finalValues.country]}</Typography>
                                                            <Button variant="contained" sx={{
                                                                backgroundColor: selectedItem === index ? '#FFFFFF' : '#174B58',
                                                                color: selectedItem === index ? '#174B58' : '#FFFFFF',
                                                                borderRadius : "50px",
                                                                textTransform: 'capitalize', 
                                                                paddingLeft: '1.8rem', 
                                                                paddingRight: '1.8rem', 
                                                                fontSize: '16px',
                                                                '&:hover': {
                                                                    backgroundColor: selectedItem === index ? '#FFFFFF' : '#174B58',
                                                                    color: selectedItem === index ? '#174B58' : '#FFFFFF'
                                                                },
                                                                '&:active': {
                                                                    backgroundColor: selectedItem === index ? '#FFFFFF' : '#174B58',
                                                                    color: selectedItem === index ? '#174B58' : '#FFFFFF'
                                                                }}}>Pay Now</Button>
                                                        </Box>
                                                    </Item>
                                                ))
                                            }
                                        </Stack>
                                    </Box>
                                </div>
                        </React.Fragment>
                </Box>
        </Box>
        <Grid sx={{marginLeft: '23%', marginTop: '2%', marginRight: '23%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} xs={12} md={12}>
            <Box sx={{ display: 'flex', flexDirection: isMobile? 'column' : 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0 || activeStep === 2 }
                    onClick={handleBack}
                    sx={{
                        backgroundColor:'#FFFFFF',
                        color:'#174B58',
                        borderRadius : "50px",
                        border: '1px solid #174B58',
                        textTransform: 'capitalize', 
                        paddingLeft: '2rem', 
                        paddingRight: '2rem', 
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        fontSize: '16px',
                        fontWeight: 'bold',}}>
                    Previous
                </Button>
                <Box sx={{ flex: '1 1 auto', marginBottom : isMobile? '20px' : '0' }} />
                <Button variant="contained" onClick={handleNext} 
                disabled={activeStep === 2 }
                sx={{
                    backgroundColor:'#174B58',
                    color:'#FFFFFF',
                    borderRadius : "50px",
                    textTransform: 'capitalize', 
                    paddingLeft: '2rem', 
                    paddingRight: '2rem', 
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    '&:hover': {
                        backgroundColor: '#174B58',
                        color: '#FFFFFF'
                    },
                    '&:active': {
                        backgroundColor: '#174B58',
                        color: '#FFFFFF'
                    }}}
                    >
                        {activeStep === steps.length - 2 ? 'Make Payment' : 'Next step'}
                </Button>
            </Box>
        </Grid>
      </Grid>
  );
}

interface EventData {
  eventType: string;
  eventDescription: string;
  eventPrice: { [key: string]: number }; // Index signature
}

const data: EventData[] = [
    {
        eventType: 'Speaker Registration (1 Day Entry Ticket)',
        eventDescription: 'This registration is for individuals who will be speaking at the conference for one day. It includes access to all conference sessions, networking opportunities, and materials for that specific day.',
        eventPrice: {
          'unitedstates': 5,
          'india': 50000,
          'france': 6,
          'england': 7
        }
    },
    {
        eventType: 'Speaker Registration (2 Days Entry Ticket)',
        eventDescription: 'This registration is for speakers attending and presenting over two days. It provides access to all sessions, networking events, and conference materials for both days.',
        eventPrice: {
          'unitedstates': 10,
          'india': 75000,
          'france': 12,
          'england': 14
        }
    },
    {
        eventType: 'Poster Presentation',
        eventDescription: 'This registration is for individuals presenting a poster at the conference. It includes access to all sessions, materials, and networking opportunities. Presenters can display their research and interact with attendees.',
        eventPrice: {
          'unitedstates': 20,
          'india': 80000,
          'france': 23,
          'england': 24
        }
    },
    {
        eventType: 'Delegate Registration',
        eventDescription: 'This registration is for non-presenting attendees. Delegates will have access to all conference sessions, materials, and networking events. It is ideal for professionals looking to learn and connect without presenting.',
        eventPrice: {
          'unitedstates': 25,
          'india': 85000,
          'france': 15,
          'england': 25
        }
    },
    {
        eventType: 'Webinar/Virtual Conference',
        eventDescription: 'This option allows participants to attend the conference virtually. It includes access to live-streamed sessions, virtual networking opportunities, and digital conference materials.',
        eventPrice: {
          'unitedstates': 30,
          'india': 40000 ,
          'france': 30,
          'england': 30
        }
    },
    {
      eventType: 'Student Registration',
      eventDescription: 'This registration is tailored for students who wish to attend the conference. It includes access to all sessions, networking events, and materials. A valid student ID may be required for verification.',
      eventPrice: {
        'unitedstates': 30,
        'india': 60000,
        'france': 30,
        'england': 30
      }
  },
  {
    eventType: 'Video Presentation',
    eventDescription: 'This option is for individuals who wish to submit a pre-recorded video presentation. It includes virtual access to the conference, where the video will be played during appropriate sessions.',
    eventPrice: {
      'unitedstates': 30,
      'india': 35000,
      'france': 30,
      'england': 30
      }
  },
  {
    eventType: 'E-poster Presentation',
    eventDescription: 'This registration is for presenters of electronic posters. It includes digital access to the conference sessions, materials, and the ability to display an e-poster for virtual attendees.',
    eventPrice: {
      'unitedstates': 30,
      'india': 25000,
      'france': 30,
      'england': 30
    }
  },
  {
    eventType: 'Accompanying Person',
    eventDescription: 'This option allows an accompanying person, such as a spouse or partner, to attend social events and selected sessions of the conference. This does not include full access to all professional sessions and materials.',
    eventPrice: {
      'unitedstates': 30,
      'india': 20000,
      'france': 30,
      'england': 30
    }
  }
  ]