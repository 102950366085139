import AbstractUploadForm from "../../Components/AbstractUploadForm";
import DrawerAppBar from "../../Components/DrawerAppBar";
import Footer from "../../Components/Footer";
import TimerCard from "../../Components/TimerCard";

const AbstractSubmissionPage = () => {
    return (
        <div>
            <DrawerAppBar/>
            <AbstractUploadForm/>
            <Footer/>
        </div>
    )
}

export default AbstractSubmissionPage;