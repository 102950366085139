import DrawerAppBar from "../../Components/DrawerAppBar";
import EsteemedCommitteePanel from "../../Components/EsteemedCommitteePanel";
import Footer from "../../Components/Footer";

const EsteemedCommitteePage = () => {
    return (
        <div>
            <DrawerAppBar/>
            <EsteemedCommitteePanel/>
            <Footer/>
        </div>
    )
}

export default EsteemedCommitteePage;