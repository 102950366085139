import starDividerImg from "../Images/starDivider.svg";

const StarDivider = () => {
    return(
        <div style={{margin: '32px 0 32px 0'}}>
            <img src={starDividerImg}/>
        </div>
    )
}

export default StarDivider;