import Accordion from "../../Components/Accordion";
import CollaboratorsMarquee from "../../Components/CollaboratorsMarquee";
import Commitee from "../../Components/Commitee";
import DrawerAppBar from "../../Components/DrawerAppBar"
import EsteemedCommittee from "../../Components/EsteemedCommittee";
import Feedbacks from "../../Components/Feedbacks";
import Footer from "../../Components/Footer";
import ImportantDates from "../../Components/ImportantDates";
import QuickLinks from "../../Components/QuickLinks";
import StatsCard from "../../Components/StatsCard";
import TitleTimer from "../../Components/TitleTimer";
import ScrollAnimation from 'react-animate-on-scroll';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ImportantDatesAlternate from "../../Components/ImportantDatesAlternate";
import PopupPage from "../../Components/PopupPage";

const HomePage = () => {

    useEffect(() => {
        AOS.init({});
      }, []);

    return (
        <div style={{overflow: 'clip'}}>
            <DrawerAppBar page_name="home"/>
            <TitleTimer/>
            <CollaboratorsMarquee/>
            <ImportantDatesAlternate/>
            <QuickLinks/>
            <StatsCard/>
            <Feedbacks/>
            <EsteemedCommittee/>
            <Footer/>
        </div>
    )
}

export default HomePage;