import React, { useEffect, useRef } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './MultiCarousel.css';
import leftArrow from "../Images/LeftArrow.svg";
import rightArrow from "../Images/RightArrow.svg";
import leftArrowDark from "../Images/leftArrowDark.svg";
import rightArrowDark from "../Images/rightArrowDark.svg";

interface MultiCarouselProps {
  items: JSX.Element[];
  page: string;
}

const MultiCarousel: React.FC<MultiCarouselProps> = ({ items, page }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const carouselRef = useRef<Carousel>(null);

  const handleLeftClick = () => {
    carouselRef.current?.previous(1);
  };

  const handleRightClick = () => {
    carouselRef.current?.next(1);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleRightClick();
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="multi-carousel-container">
      <Carousel
        responsive={responsive}
        containerClass="carousel-container"
        itemClass="carousel-item"
        infinite={true}
        arrows={false}
        ref={carouselRef}
      >
        {items}
      </Carousel>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: page === 'venue' ? '0' : '40px 0 0 0',
        }}
        className="custom-button-group"
      >
        <img width={60} onClick={handleLeftClick} src={page === 'venue' ? leftArrowDark : leftArrow} />
        <img width={60} onClick={handleRightClick} src={page === 'venue' ? rightArrowDark : rightArrow} />
      </div>
    </div>
  );
};

export default MultiCarousel;
