import { Box, Fab, Grid, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import scientificlogo from "../Images/scientificlogo.svg"
import accommodationlogo from "../Images/Buildings.svg"
import registrationlogo from "../Images/Registration.svg"
import abstractlogo from "../Images/AbstractSubmission.svg"
import brochurelogo from "../Images/Brochure.svg"

const QuickLinks = () => {
    const isMobile = useMediaQuery('(max-width:1240px)');
    return (
        <div style={{backgroundColor: '#174B58'}}>
            <div style={{margin: '100px auto 0px auto', maxWidth: '1000px', padding: '40px 0 40px 0', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '50px', color: '#FFFFFF'}}>
                    Quick Links
                </Typography>
                <Stack direction= {isMobile ? "column" : "row"} spacing={1} sx={{display:'flex', justifyContent: 'space-between' , paddingTop: '32px', paddingBottom: '32px'}}>
                        <Fab variant="extended" 
                        sx={{
                        backgroundColor: '#FFFFFF', 
                        marginBottom: isMobile? '0px' : 0,
                        width: '15em',
                        color: '#143F49',
                        textTransform: 'none', 
                        fontSize: '16px', 
                        fontWeight: 'bold', 
                        paddingLeft: '38px', 
                        paddingRight: '38px', 
                        paddingTop: '25px', 
                        paddingBottom: '25px',
                        display: 'flex',
                        flexDirection: 'row', // Ensure items are in a row
                        alignItems: 'center', // Center items vertically
                        justifyContent: 'space-between' // Spacing between items
                        }}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img alt="logo" style={{ marginRight: '8px' }} src={scientificlogo}/>
                                <Typography>Scientific Sessions</Typography>
                            </div>
                        </Fab>
                        <Fab variant="extended" 
                        sx={{
                        backgroundColor: '#FFFFFF', 
                        marginTop: isMobile ? '20px !important' : '0px',
                        width: '15em',
                        color: '#143F49',
                        textTransform: 'none', 
                        fontSize: '16px', 
                        fontWeight: 'bold', 
                        paddingLeft: '38px', 
                        paddingRight: '38px', 
                        paddingTop: '25px', 
                        paddingBottom: '25px',
                        display: 'flex',
                        flexDirection: 'row', // Ensure items are in a row
                        alignItems: 'center', // Center items vertically
                        justifyContent: 'space-between' // Spacing between items
                        }}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img alt="logo" style={{ marginRight: '8px' }} src={accommodationlogo}/>
                                <Typography>Accommodation</Typography>
                            </div>
                        </Fab>
                        <Fab variant="extended" 
                        sx={{
                        backgroundColor: '#FFFFFF', 
                        marginTop: isMobile ? '20px !important' : '0px',
                        color: '#143F49',
                        width: '15em',
                        textTransform: 'none', 
                        fontSize: '16px', 
                        fontWeight: 'bold', 
                        paddingLeft: '38px', 
                        paddingRight: '38px', 
                        paddingTop: '25px', 
                        paddingBottom: '25px',
                        display: 'flex',
                        flexDirection: 'row', // Ensure items are in a row
                        alignItems: 'center', // Center items vertically
                        justifyContent: 'space-between' // Spacing between items
                        }}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img alt="logo" style={{ marginRight: '8px' }} src={registrationlogo}/>
                                <Typography>Registration</Typography>
                            </div>
                        </Fab>
                        <Fab variant="extended" 
                        sx={{
                        backgroundColor: '#FFFFFF', 
                        marginTop: isMobile ? '20px !important' : '0px',
                        color: '#143F49',
                        width: '15em',
                        textTransform: 'none', 
                        fontSize: '16px', 
                        fontWeight: 'bold', 
                        paddingLeft: '38px', 
                        paddingRight: '38px', 
                        paddingTop: '25px', 
                        paddingBottom: '25px',
                        display: 'flex',
                        flexDirection: 'row', // Ensure items are in a row
                        alignItems: 'center', // Center items vertically
                        justifyContent: 'space-between' // Spacing between items
                        }}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img alt="logo" style={{ marginRight: '8px'}} src={abstractlogo}/>
                                <Typography>Abstract Submission</Typography>
                            </div>
                        </Fab>
                        <Fab variant="extended" 
                        href='test.pdf' download="object.pdf"
                        sx={{
                        backgroundColor: '#FFFFFF', 
                        marginTop: isMobile ? '20px !important' : '0px',
                        color: '#143F49',
                        width: '15em',
                        textTransform: 'none', 
                        fontSize: '16px', 
                        fontWeight: 'bold', 
                        paddingLeft: '38px', 
                        paddingRight: '38px', 
                        paddingTop: '25px', 
                        paddingBottom: '25px',
                        display: 'flex',
                        flexDirection: 'row', // Ensure items are in a row
                        alignItems: 'center', // Center items vertically
                        justifyContent: 'space-between' // Spacing between items
                        }}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img alt="logo" style={{ marginRight: '8px' }} src={brochurelogo}/>
                                <Typography>Download Brochure</Typography>
                            </div>
                        </Fab>
                </Stack>
            </div>
        </div>
    )
}

export default QuickLinks;