import { Divider, Grid, Typography, useMediaQuery } from "@mui/material"
import StarDivider from "./StarDivider";
import PanelMem1 from "../Images/PanelMem1.png"
import PanelMem2 from "../Images/PanelMem2.png";
import PanelMem3 from "../Images/PanelMem3.png";
import PanelMem4 from "../Images/PanelMem4.png";
import PanelMem5 from "../Images/PanelMem5.png";
import PanelMem6 from "../Images/PanelMem6.png";
import PanelMem7 from "../Images/PanelMem7.png";
import PanelMem8 from "../Images/PanelMem8.png";

const EsteemedCommitteePanel = () => {
    const isMobile = useMediaQuery('(max-width:1035px)');
    return (
        <div style={{margin: '30px 7% 0 7%'}}>
            <Grid container columns={12}>
                <Grid item sx={{paddingRight: '10%'}} xs={12}>
                    <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginBottom: '16px'}}>Our Esteemed Committee</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{marginBottom: '80px', fontSize: '20px'}}>The success of NSSA conferences wouldn't be possible without the dedication and expertise of our esteemed committee members.  This distinguished group brings a wealth of knowledge and experience from diverse backgrounds in all industry.  Their guidance and leadership are instrumental in shaping the content, selecting speakers, and ensuring our events deliver exceptional value to attendees.</Typography>
                </Grid>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {
                    committeePanelDetails.map((item, index) => (
                        <>
                            <Grid container xs={12} md={6} sx={{ paddingRight: '3%' }}>
                                <Grid item xs={12} md={4} sx={{display: 'flex', justifyContent: isMobile ? 'center' : ''}}>
                                    <img width={150} height={150} src={item.member_img} />
                                </Grid>
                                <Grid item xs={12} md={8} sx={{ marginTop: { xs: '16px', md: 0 }, paddingLeft: { xs: '0', md: '24px' } }}>
                                    <Typography>{item.member_description}</Typography>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ marginTop: '24px', display: 'flex', flexDirection: 'column',alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>{item.member_name}</Typography>
                                    <Typography sx={{ fontSize: '16px' }}>{item.member_designation}</Typography>
                                </Grid>
                                <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: isMobile? '20px' : '0', justifyContent: 'flex-end', marginTop: '24px' }}>
                                    <Typography>{item.member_location}</Typography>
                                    <Typography>{item.member_city}</Typography>
                                </Grid>
                            </Grid>
                            {
                                isMobile ? <Grid item xs={12}>
                                <hr style={{ margin: '50px 0', color: '#D9D9D9' }} />
                            </Grid> :
                            (index + 1) % 2 === 0 && index !== committeePanelDetails.length - 1 && 
                                <Grid item xs={12}>
                                    <hr style={{ margin: '50px 0', color: '#D9D9D9' }} />
                                </Grid>
                                
                                }
                        </>

                    ))
                }
                </div>
            </Grid>
        </div>
        
    )
}

const committeePanelDetails = [
    {
        member_name : 'Radu Mutihac',
        member_img: PanelMem1,
        member_designation: 'Chief Medical Officer',
        member_description: 'Prof. Emeritus Radu Mutihac is Chair of Medical Physics Section, University of Bucharest, and works in Neuroscience, Signal Processing, Microelectronics, and Artificial Intelligence. As postdoc/research associate/visiting professor/full professor, he run his research at the University of Bucharest, International Centre for Theoretical Physics (Italy), Ecole Polytechnique and Institute Henri (France), KU Leuven (Belgium). Data mining and exploratory analysis of neuroimaging time series were addressed during two Fulbright Grants in Neuroscience (Yale University, CT, and the University of New Mexico, NM, USA). His research in fused biomedical imaging modalities was carried out at the Johns Hopkins University, National Institutes of Health, and Walter Reed Army Institute of Research, MD, USA.',
        member_location: 'Emeritus University of Bucharest',
        member_city: 'Romania'
    },
    {
        member_name : 'Ching-Kuan Liu',
        member_img: PanelMem2,
        member_designation: 'Chief Financial Officer',
        member_description: 'Prof. Ching-Kuan Liu, MD, PhD was born in Kaohsiung, Taiwan in 1956. He graduated from the Kaohsiung Medical University (KMU) School of Medicine with honors such as being the commencement speaker on commencement ceremony and receiving the Medal of Four Characters in 1982. He completed his residency training in Neurology at KMU hospital. Subsequently, he received his master and PhD degrees from Graduate Institute of Medicine at KMU, where he was a distinguished graduate. He completed the Neurobehavior Fellowship Program and was a Clinical Instructor at Reed Neurological Institute of UCLA from 1988 to 1990. After returning to Taiwan, he became the Director of the Graduate Institute of Behavioral Sciences of KMU, the Chairman of the Department of Neurology of KMU & Hospital , the Dean of InfoTech of KMU, the Vice-Superintendent of the KMU Hospital, and the Superintendent of Kaohsiung Municipal Hsia-Kang Hospital. He just finished two 3-year terms as President of Kaohsiung Medical University. He earned the Paragon of Taiwanese Medical Doctors Award from the Taiwan Medical Association in 2015.',
        member_location: 'Kaohsiung Medical University',
        member_city: 'Taiwan'
    },
    {
        member_name : 'Robert Buckingham',
        member_img: PanelMem3,
        member_designation: 'Doctor',
        member_description: 'Dr. Robert Buckingham is an internist in Ojai, California and is affiliated with Santa Barbara Cottage Hospital. He received his medical degree from the University of Illinois College of Medicine and has been in practice for 40 years. His specialities include Internal Medicine, Sleep Medicine, Noninvasive Cardiology, Critical Care and Intensive Care, Consultant in Internal Medicine, author and writer on vascular inflammatory prevention and life extension',
        member_location: 'Santa Barbara Cottage Hospital',
        member_city: 'USA'
    },
    {
        member_name : 'Abraham Z. Snyder',
        member_img: PanelMem4,
        member_designation: 'Doctor',
        member_description: 'Dr. Snyder is a neurologist and physiologist with expertise in neuroimaging. Dr. Snyder received a PhD in physiology form Rockefeller University in 1977 and an MD from the State University of New York at Buffalo in 1981. He currently is a Research Professor at Washington University School of Medicine in Saint Louis, Missouri, USA. His primary interest is development of methodology for analysis of resting state fMRI data.',
        member_location: 'Washington University',
        member_city: 'St. Louis, MO, USA'
    },
    {
        member_name : 'Laxman Gangwani',
        member_img: PanelMem5,
        member_designation: 'Doctor',
        member_description: 'Dr. Laxman Gangwani has made leading contributions in the field of neurosciences specifically in neuromuscular disorders, spinal muscular atrophy (SMA) and amyotrophic lateral sclerosis (ALS). He is currently Professor in the Department of Veterinary Pathobiology, University of Missouri, USA. Dr. Gangwanis research is focused on understanding the molecular basis of neurodegeneration associated with the pathogenesis of neurological disorders. He recently identified ZPR1 as a protective modifier of SMA and ALS4. The current focus of research is to understand the molecular mechanisms of transcription-coupled R-loop resolution under normal and disease conditions, and of neurodegeneration caused by altered R-loop metabolism.',
        member_location: 'University of Missouri',
        member_city: 'Columbia, Missouri, USA'
    },
    {
        member_name : 'Brandon Lucke-Wold',
        member_img: PanelMem6,
        member_designation: 'Doctor',
        member_description: 'Brandon Lucke-Wold was born and raised in Colorado Springs, CO. He graduated magna cum laude with a BS in Neuroscience and distinction in honors from Baylor University. He completed his MD/PhD, Masters in Clinical and Translational Research, and the Global Health Track at West Virginia University School of Medicine. His research focus was on traumatic brain injury, neurosurgical simulation, and stroke. At West Virginia University, he also served as a health coach for the Diabetes Prevention and Management program in Morgantown and Charleston, WV, which significantly improved health outcomes for participants. In addition to his research and public health projects, he is a co-founder of the biotechnology company Wright-Wold Scientific, the pharmaceutical company CTE cure, and was a science advocate on Capitol Hill through the Washington Fellows program. He has also served as president of the WVU chapters for the American Association of Pharmaceutical Scientists, Neurosurgery Interest group, and Erlenmeyer Initiative Entrepreneur group. In addition, he has served as vice president for the graduate student neuroscience interest group, Nu Rho Psi Honor Society, and medical students for global health. He was an active member of the Gold Humanism Honor Society and Alpha Omega Alpha Honor Society. He is currently a member of the UF House Staff Council, Positive Culture Committee, Quality Improvement Committee, Board of Directors Alachua County Medical Society, and Accreditation Requirements Review Committee.',
        member_location: 'University of Florida',
        member_city: 'USA'
    },
    {
        member_name : 'Owen J Connolly',
        member_img: PanelMem7,
        member_designation: 'Doctor',
        member_description: 'Owen Connolly - Owner & Senior Counselling Psychologist at the Owen Connolly Counselling Therapy & Education Centre. I have practised in the USA, Lebanon and the UK, now full-time in Ireland. I have over 50,000 client hours as a Counsellor. Qualifications: BSc (Hons) Psych, Cert Hsc, Cert Social Sc., Miit Grad. Professional membership: I.S.T.S.S., U.S.T.S.S., Committee member of the child & adolescent special interest group PSI, Marriage & Family therapist ILM. Publications: Author of the best selling book, the Father role as a parent: Standing on the Shoulders of Giants, Co-author of Parenting in the Millenium. Social Media: Broadcaster and regular contributor to TV Now - magazine, Ask the expert section, with regular contributions in the Irish Independent/Herald/Sunday World & Irish Times on a huge range of family related topics. Other memberships: Chairman of the Education Research Trust Ltd. Member of IAIMH (Irish Association of Infant Mental Health). Director and Board Member of PPS (Psychologists Protection Society), Protecting counsellors, psychologists & psychotherapists for over 35 years Member of ACAMH (Association for Child & Adolescent Mental Health) Chairman of AMEN supporting men experiencing domestic abuse',
        member_location: 'Owen Connolly Counselling',
        member_city: 'Ireland'
    },
    {
        member_name : 'Santiago Restrepo',
        member_img: PanelMem8,
        member_designation: 'Doctor',
        member_description: 'Dr. Snyder is a neurologist and physiologist with expertise in neuroimaging. Dr. Snyder received a PhD in physiology form Rockefeller University in 1977 and an MD from the State University of New York at Buffalo in 1981. He currently is a Research Professor at Washington University School of Medicine in Saint Louis, Missouri, USA. His primary interest is development of methodology for analysis of resting state fMRI data.',
        member_location: 'Universidad de Barcelona',
        member_city: 'Spain'
    },
]

export default EsteemedCommitteePanel;