import DrawerAppBar from "../../Components/DrawerAppBar";
import Footer from "../../Components/Footer";
import ScientificSessionsPageComp from "../../Components/ScientificSessionsPageComp";
import TimerCard from "../../Components/TimerCard";

const ScientificSessionsPage = () => {
    return (
        <div>
            <DrawerAppBar/>
                <ScientificSessionsPageComp/>
                <TimerCard/>
            <Footer/>
        </div>
    )
}

export default ScientificSessionsPage;