import DrawerAppBar from "../../Components/DrawerAppBar";
import Footer from "../../Components/Footer";
import IndustryExhibitionComp from "../../Components/IndustryExhibitionComp";
import TimerCard from "../../Components/TimerCard";

const IndustryAndExhibitionPage = () => {
    return (
        <div>
            <DrawerAppBar/>
            <IndustryExhibitionComp/>
            <TimerCard/>
            <Footer/>
        </div>
    )
}

export default IndustryAndExhibitionPage;