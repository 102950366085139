import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { Button, Fab, Grid, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material';
import {
  createStyles,
  fade,
  Theme,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import { useRef, useState } from 'react';
import AWS from 'aws-sdk';

interface FormValues {
  [key: string]: any;
}

interface RegistrationDetails {
  type : string,
  amount : number
}

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#000000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000000',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#174B58',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#174B58',
      },
    },
  },
})(TextField);

const countries = [
  {
    value: 'india',
    label: 'India',
  },
  {
    value: 'unitedstates',
    label: 'United States',
  },
  {
    value: 'england',
    label: 'England',
  },
  {
    value: 'france',
    label: 'France',
  },
];


export default function AbstractUploadForm() {
  const hiddenFileInput: any = useRef(null);
  const [file, setFile] = useState<File>();
  const [fileVisibility, setFileVisibility] = useState("none");
  const [fileVisibility2, setFileVisibility2] = useState("block");
  const formRefs = useRef<FormValues>({formsType: 'abstract', amount: 0, type: 'nil'});
  const [registrationDetails, setRegistrationDetails] = React.useState<RegistrationDetails | null>({
    type: 'empty',
    amount: 111
  });
  const [loading, setLoading] = useState(false); // State for loader

  const s3 = new AWS.S3({
    accessKeyId: "AKIAVRUVSYJT4DF4G3GT",
    secretAccessKey: "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",
    region: "eu-north-1"
  });
  
  const uploadFileToS3 = async (file: File) => {
    const params: any = {
      Bucket: "registrationpdf",
      Key: `abstracts/${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type
    };
  
    try {
      const data = await s3.upload(params).promise();
      return data.Location; // Return the URL of the uploaded file
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      throw error;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    formRefs.current[name] = value;
  };

  const handleChangeFileUpload = (event : any) : any => {
    const selectedFile = event.target.files[0];
    const reader: any = new FileReader();
    setFile(reader.result); 
    setFileVisibility("block")
    setFileVisibility2("none")
    formRefs.current["abstract"] = event.target.files[0];
    console.log(selectedFile)
  }

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const onSubmitClicked = async () => {

    const requiredFields = ['firstName', 'lastName', 'phoneNumber', 'emailAddress', 'country', 'address', 'abstract'];
      for (let field of requiredFields) {
        if (!formRefs.current[field]) {
          alert(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
          return; // Prevent advancing to the next step
        }
      }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number without any spaces or special characters

    const emailValid = emailRegex.test(formRefs.current.emailAddress);
    const phoneValid = phoneRegex.test(formRefs.current.phoneNumber);
    
      if (!emailValid) {
        alert("Please enter a valid email address.");
        return;
      }
      if (!phoneValid) {
        alert("Please enter a valid 10-digit phone number.");
        return;
      }

      setLoading(true); // Start loading

      const abstractFile: File = formRefs.current.abstract;
      const abstractFileUrl = await uploadFileToS3(abstractFile);
      // Update finalValues with the S3 URL
      const updatedFinalValues: FormValues = { ...formRefs.current, abstract: abstractFileUrl };
      console.log(updatedFinalValues);

      // Combine finalValues and registrationDetails
      const combinedData = { ...updatedFinalValues, ...registrationDetails };
      console.log(combinedData);

      // invoke lambda function for inserting the funtion to the database.
      // Call the Lambda function via API Gateway
      const response = await fetch('https://jbgyp7s33h3fx6nlmavrhic35y0zklpl.lambda-url.eu-north-1.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(combinedData)
      });

      if (!response.ok) {
        alert('Error submitting abstract. Please try again later.');
        throw new Error('Network response was not ok');
      }
      setLoading(false); // Stop loading
      const result = await response.json();
      console.log('Data stored successfully:', result);
      alert('Abstract submitted successfully');
  }

  const isMobile = useMediaQuery('(max-width:1035px)');

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const LoaderPopup = () => (
    <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please wait, you form is being submitted
          </Typography>
        </Box>
      </Modal>
  );

  return (
    <Grid container rowSpacing={2} columns={{ xs: 12 }} sx={{ marginTop: '30px' }} alignItems="center" justifyContent="center">
      
      <Grid sx={{marginBottom: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center'}} xs={12} md={12}>
        <Typography sx={{fontWeight: 'bold', fontSize: '34px'}}>Abstract Upload Form</Typography>
        <Typography sx={{fontSize: '16px'}}>Please fill the form below to upload your abstract</Typography>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: isMobile? '80%' : '55%',
          margin: 'auto',
          boxShadow: 4, // Adjust shadow intensity as needed
          borderRadius: 8, // Add border radius for a softer look
          p: 7,
          paddingBottom: '50px', // Add padding for better separation from the box
        }}
      >
        <Grid container columnSpacing={10} rowSpacing={5}>
          <Grid item xs={12} md={12}>
            <Typography sx={{fontSize: '26px', fontWeight: 'bold', marginBottom: '8px'}}>Contact Details</Typography>
            <Typography sx={{fontSize: '14px', color: '#646464'}}>Please fill your information so we can get in touch with you.</Typography>
          </Grid>
          
          <Grid item xs={12} md={6}>
          {loading && <LoaderPopup />} {/* Render loader if loading is true */}
            <CustomTextField
              id="outlined-required"
              label="First Name"
              name="firstName"
              onChange={handleChange}
              helperText=""
              fullWidth
              InputProps={{ sx: { borderRadius: '9px' } }}
              sx={{
                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                borderRadius: '9px',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
                id="outlined-required"
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                helperText=""
                fullWidth
                InputProps={{ sx: { borderRadius: '9px' } }}
                sx={{
                  boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                  borderRadius: '9px',
                }}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
                id="outlined-required"
                label="Phone Number"
                name="phoneNumber"
                onChange={handleChange}
                type='number'
                helperText=""
                fullWidth
                InputProps={{ sx: { borderRadius: '9px' } }}
                sx={{
                  boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                  borderRadius: '9px',
                }}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
                id="outlined-required"
                label="Email Address"
                name="emailAddress"
                onChange={handleChange}
                helperText=""
                fullWidth
                InputProps={{ sx: { borderRadius: '9px' } }}
                sx={{
                  boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                  borderRadius: '9px',
                }}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
                id="outlined-required"
                label="Country"
                name="country"
                onChange={handleChange}
                select
                defaultValue="unitedstates"
                helperText=""
                fullWidth
                InputProps={{ sx: { borderRadius: '9px' } }}
                sx={{
                  boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                  borderRadius: '9px',
                }}>
                  {countries.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomTextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
                id="outlined-required"
                label="Address"
                name="address"
                onChange={handleChange}
                helperText=""
                fullWidth
                InputProps={{ sx: { borderRadius: '9px' } }}
                sx={{
                  boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)', 
                  borderRadius: '9px',
                }}
              />
          </Grid>
          <Grid item xs={12} md={6}>
          <div className='file-uploader'>
            <div className='file-div'>
                <Button className='attachment-file' onClick={handleClick} fullWidth disableElevation disableRipple>
                    <input type='file' id='actual-btn' accept={'pdf'} ref={hiddenFileInput} onChange={handleChangeFileUpload} hidden data-testid='file-upload-input'/>
                    <div className="filename">
                        {/* Conditionally render the file content */}
                        {/* {file ? <div style={{display: "fileVisibility" }}>File Uploaded</div>: <div style={{display: fileVisibility2}}>Choose file</div> } */}
                        <div style={{display: fileVisibility }}>File uploaded</div>
                        <div style={{display: fileVisibility2}}>Choose file</div>
                    </div>
                    <AttachFileOutlinedIcon style={{width: '30px', height: '30px'}} className='attach-icon'/>
                </Button>
            </div>
        </div>
          </Grid>
        </Grid>
      </Box>
      <Grid sx={{display: 'flex', justifyContent: 'center', marginTop: '43px'}} xs={12}>
        <Fab variant="extended" 
          onClick={onSubmitClicked}
          sx={{
              backgroundColor: '#174B58',
              color: '#FFFFFF',
              border: '1px solid #174B58',
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 'bold',
              paddingLeft: '51px',
              paddingRight: '51px',
              paddingTop: '18px',
              paddingBottom: '18px',
              '&:hover': {
                  backgroundColor: '#174B58',
                  color: '#FFFFFF'
              },
              '&:active': {
                  backgroundColor: '#174B58',
                  color: '#FFFFFF'
              }
          }}>
          Submit
        </Fab>
      </Grid>
                        
    </Grid>
  );
}

