import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage/HomePage';
import CongressFaqPage from './Pages/CongressFaqPage/CongressFaqPage';
import AbstractSubmissionPage from './Pages/AbstractSubmissionPage/AbstractSubmissionPage';
import RegistrationPage from './Pages/RegistrationPage/RegistrationPage';
import AboutUsPage from './Pages/AboutUsPage/AboutUsPage';
import EsteemedCommitteePage from './Pages/EsteemedCommitteePage/EsteemedCommitteePage';
import AccomodationPage from './Pages/AccomodationPage/AccomodationPage';
import IndustryAndExhibitionPage from './Pages/IndustryAndExhibitionPage/IndustryAndExhibitionPage';
import CompaniesAndSocietyPage from './Pages/CompaniesAndSocietyPage/CompaniesAndSocietyPage';
import ScientificSessionsPage from './Pages/ScientificSessionsPage/ScientificSessionsPage';
import VenuePage from './Pages/VenuePage/VenuePage';
import ExplorePage from './Pages/ExplorePage/ExplorePage';

function App() {
  return (
    <Routes>
      <Route path='/' element={<HomePage/>}/>
      <Route path='/congressfaq' element={<CongressFaqPage/>}/>
      <Route path='/abstractsubmission' element={<AbstractSubmissionPage/>}/>
      <Route path='/register' element={<RegistrationPage/>}/>
      <Route path='/aboutus' element={<AboutUsPage/>}/>
      <Route path='/explorenow' element={<ExplorePage/>}/>
      <Route path='/esteemedcommittee' element={<EsteemedCommitteePage/>}/>
      <Route path='/accomodation' element={<AccomodationPage/>}/>
      <Route path='/industryexhibition' element={<IndustryAndExhibitionPage/>}/>
      <Route path='/companiesandsociety' element={<CompaniesAndSocietyPage/>}/>
      <Route path='/scientificsessions' element={<ScientificSessionsPage/>}/>
      <Route path='/venue' element={<VenuePage/>}/>
    </Routes>
  );
}

export default App;
