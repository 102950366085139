import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import panelmember1 from "../Images/PanelMem1.png";
import panelmemner2 from "../Images/PanelMem2.png";
import panelmember3 from "../Images/PanelMem3.png";
import panelmember4 from "../Images/PanelMem4.png";

interface CommitteePanel {
    displayPicture: string;
    name: string;
    designation: string;
    hospitalName: string;
    location: string;
  }


const EsteemedCommittee = () => {
    const metrics: CommitteePanel[] = [
        { displayPicture: panelmember1, name: 'Prof. Radu Mutihac', designation: 'Chief Medical Officer', hospitalName: 'University of Bucharest', location: 'Romania'},
        { displayPicture: panelmemner2, name: 'Prof. Ching-Kuan Liu', designation: 'Chief Financial Officer', hospitalName: 'Kaohsiung University', location: 'USA'},
        { displayPicture: panelmember3, name: 'Dr. Robert Buckingham', designation: 'Doctor', hospitalName: 'Santa Barbara Cottage Hospital', location: 'New York'},
        { displayPicture: panelmember4, name: 'Dr. Abraham Z. Snyder', designation: 'Doctor', hospitalName: 'Washington University in Saint Louis', location: 'USA'},
      ];

      const isMobile = useMediaQuery('(max-width:1150px)');
    return (
        <div style={{margin: '100px auto 0 auto', padding: '0 20px 0 20px', maxWidth: '1300px'}}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={isMobile? 12 : 6} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingRight: '20px', paddingBottom: isMobile? '50px' : '0'}}>
                    <Typography sx={{fontSize: '40px', color: '#174B58', fontFamily: 'Big Shoulders Display Black'}}>Our Esteemed Committee</Typography>
                    <Typography sx={{color: '#28272C', fontSize: '20px', paddingTop: '20px'}}>
                        The success of NSSA conferences wouldn't be possible without the dedication and expertise of our esteemed committee members.  This distinguished group brings a wealth of knowledge and experience from diverse backgrounds in all industry.  Their guidance and leadership are instrumental in shaping the content, selecting speakers, and ensuring our events deliver exceptional value to attendees.
                    </Typography>
                </Grid>
                <Grid container md={isMobile? 12 : 6}>
                {metrics.map((values) => (
                    <Grid item xs={12} md={isMobile? 12 : 6}>
                        <Box sx={{margin: '0px 0px 30px 0px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box
                                sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: 'fit-content',
                                margin: 'auto',
                                boxShadow: 4, // Adjust shadow intensity as needed
                                borderRadius: 8, // Add border radius for a softer look
                                p: 4, // Add padding for better separation from the box
                                }}>
                                
                                    <CommitteeMemberCard displayPicture={values.displayPicture} name={values.name} designation={values.designation} hospitalName={values.hospitalName} location={values.location}/>
                            </Box>
                        </Box>
                    </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>
    )
}

function CommitteeMemberCard({ displayPicture, name, designation, hospitalName, location }: CommitteePanel): React.ReactElement {
    return (
      <Box sx={{ width: '215px', margin: '0px', textAlign: 'center' }}>
        <img width={130} src={displayPicture}/>
        <Typography sx={{fontWeight: 'bold', fontSize: '20px', paddingTop: '40px'}}>
          {name}
        </Typography>
        <Typography sx={{ fontSize: '16px' }}>
          {designation}
        </Typography>
        <Typography sx={{paddingTop: '15px'}}>
            {hospitalName}
        </Typography>
        <Typography>
            {location}
        </Typography>
      </Box>
    );
  }

export default EsteemedCommittee;