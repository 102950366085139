import { Accordion } from "@mui/material";
import Accordionfunction from "../../Components/Accordion";
import DrawerAppBar from "../../Components/DrawerAppBar";
import Footer from "../../Components/Footer";
import TimerCard from "../../Components/TimerCard";

const CongressFaqPage = () => {
    return (
        <div>
            <DrawerAppBar/>
            <Accordionfunction/>
            <TimerCard/>
            <Footer/>
        </div>
    )
}

export default CongressFaqPage;