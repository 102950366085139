import * as React from 'react';
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css";
import { Fab, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import './TimerCard.css'
import './GlobalStyling.css'

export default function TimerCard() {
      const [days, setDays] = useState(0);
      const [hours, setHours] = useState(0);
      const [minutes, setMinutes] = useState(0);
      const [seconds, setSeconds] = useState(0);

      const deadline = "September, 11, 2024";

      const getTime = () => {
        const time = Date.parse(deadline) - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
      }

      useEffect(() => {
        const interval = setInterval(() => getTime(), 1000);
    
        return () => clearInterval(interval);
      }, []);

      const isMobile = useMediaQuery('(max-width:1035px)');
      const isSmallDisplay = useMediaQuery('(max-width:600px');
      const theme = useTheme();
      const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
  return (
    
    // <div style={{paddingBottom:'50px', backgroundColor: '#082932', width: isMobile ? '100%' : 'auto', overflowX: isMobile ? 'hidden' : 'visible' }}>
      <Grid container
      spacing={15}
      columns={{ xs: 12 }}
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      sx={{
        maxWidth: '100%', // Set maximum width
        margin: '0 auto', // Horizontally center the Grid container
        backgroundColor: 'white'
      }}>
        <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '70%',
                  boxShadow: 4, // Adjust shadow intensity as needed
                  borderRadius: 8, // Add border radius for a softer look
                  p: 3,
                  paddingBottom: '50px', // Add padding for better separation from the box
                  justifyContent: 'center', // Center the content horizontally
                  alignItems: 'center', // Center the content vertically
                }}
            >
                <Grid item sx={{marginTop: '40px', maxWidth: '100%'}}>
                    <Chip label="Upcoming" variant="outlined" sx={{color: '#000000'}}/>
                    <Typography sx={{
                      fontFamily: 'Big Shoulders Display Black',
                      fontSize: isMobile ? 'calc(5vw + 10px)' : '60px', // Responsive font size using vw unit
                      color: '#174B58',
                      maxWidth: '100%', // Ensure text doesn't overflow parent
                      whiteSpace: 'nowrap', // Prevent text from wrapping
                    }}>
                        NEUROSCIENCE &
                    </Typography>
                    <Typography sx={{
                    fontFamily: 'Big Shoulders Display Black',
                    fontSize: isMobile ? 'calc(5vw + 10px)' : '60px', // Responsive font size using vw unit
                    color: '#174B58',
                    maxWidth: '100%', // Ensure text doesn't overflow parent
                    whiteSpace: 'nowrap', // Prevent text from wrapping
                  }}>

                        PSYCHIATRY 2024
                    </Typography>
                    {
                      isSmallDisplay ? (
                        <>
                          <Typography sx={{
                              color: '#808080',
                              fontSize: '18px',
                              display: 'flex',
                              flexDirection: 'column', // Display the elements in a column layout
                              alignItems: 'center',
                          }}>
                              Sept 11-12, 2024
                          </Typography>
                          <Typography sx={{
                              display: 'flex',
                              color: '#808080',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: '18px',
                          }}>
                              <span style={{ marginRight: '5px' }}>•</span>
                              Dubai UAE
                          </Typography>
                        </>
                      ) : (
                        <Typography sx={{
                          color: '#808080',
                          fontSize: '18px',
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: isSmallDisplay?  'column' : 'row'
                      }}>
                          September 11-12, 2024 
                          <span style={{ margin: '0 5px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10px' }}>•</span> 
                          Dubai UAE
                      </Typography>
                      )
                    }
                </Grid>
                <Grid item sx={{maxWidth: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography sx={{ fontFamily: 'DM Sans, sans-serif', top:'100px', fontSize: '24px', color: '#000000' }}>
                          Commences in
                      </Typography>
                    <div className="timer" role="timer" style={{color: '#000000', margin: 0, paddingTop: '16px'}}>
                      <div className="col-4">
                        <div className="box">
                          <p id="day" style={{fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '40px'}}>{days < 10 ? "0" + days : days}</p>
                          <span className="text" style={{fontFamily: 'Roboto', fontSize: '19px'}}>Days</span>
                        </div>
                          <span className="colon">:</span>
                      </div>
                      <div className="col-4">
                          <div className="box" style={{paddingLeft: isSmallDisplay ? '0' : '5px'}}>
                            <p id="hour" style={{fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '40px'}}>{hours < 10 ? "0" + hours : hours}</p>
                            <span className="text" style={{fontFamily: 'Roboto', fontSize: '19px'}}>Hours</span>
                          </div>
                          <span className="colon">:</span>
                      </div>
                      <div className="col-4">
                          <div className="box" style={{paddingLeft: isSmallDisplay ? '0' : '5px'}}>
                            <p id="minute" style={{fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '40px'}}>{minutes < 10 ? "0" + minutes : minutes}</p>
                            <span className="text" style={{fontFamily: 'Roboto', fontSize: '19px'}}>Minutes</span>
                          </div>
                          <span className="colon">:</span>
                      </div>
                      <div className="col-4">
                          <div className="box" style={{paddingLeft: isSmallDisplay ? '0' : '25px'}}>
                            <p id="second" style={{fontFamily: 'Roboto', fontWeight: 'bold',fontSize: '40px'}}>{seconds < 10 ? "0" + seconds : seconds}</p>
                            <span className="text" style={{fontFamily: 'Roboto', fontSize: '19px'}}>Seconds</span>
                          </div>
                      </div>
                    </div>
                    <Stack direction= {isMobile ? "column" : "row"} spacing={1} sx={{paddingTop: '32px'}}>
                        <Fab variant="extended" 
                        sx={{
                            backgroundColor: '#174B58',
                            color: '#FFFFFF',
                            border: '1px solid #174B58',
                            textTransform: 'none',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            paddingLeft: '51px',
                            paddingRight: '51px',
                            paddingTop: '18px',
                            paddingBottom: '18px',
                            '&:hover': {
                                backgroundColor: '#174B58',
                                color: '#FFFFFF'
                            },
                            '&:active': {
                                backgroundColor: '#174B58',
                                color: '#FFFFFF'
                            }
                        }}>
                        Register Now
                        </Fab>
                    </Stack>
                    </div>
                </Grid>
        </Box>
      </Grid>
    
  );
}
