import { Typography } from "@mui/material";
import industryExhibitionImg from "../Images/industryExhibitionImg.svg"

const IndustryExhibitionComp = () => {
    return (
        <div style={{margin: '30px 7% 80px 7%'}}>
            <img style={{width: '100%', maxWidth: '100%'}} src={industryExhibitionImg}/>
            <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginTop: '48px'}}>Industry and Exhibition</Typography>
            <Typography sx={{fontSize: '20px', marginTop: '20px'}}>Explore the forefront of technology, products, medical devices, and innovations in the field of
            Neuroscience & Psychiatry at our exhibition!</Typography>
            <Typography sx={{fontSize: '20px', marginTop: '20px'}}>
            At Neuroscience & Psychiatry 2024, take the opportunity to:
            <ul style={{marginLeft: '20px', marginTop: '10px'}}>
                <li>Stay informed about the latest offerings from our industry partners</li>
                <li>Engage with representatives from various companies and organizations</li>
                <li>Connect with experts, international societies, peers, and more to foster meaningful discussions</li>
            </ul>
            </Typography>

            <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginTop: '48px'}}>Guided Tours</Typography>
            <Typography sx={{fontSize: '20px', marginTop: '20px'}}>Embark on a guided tour of the exhibition hall led by experienced guides with extensive clinical
knowledge in your area of interest. These interactive sessions provide a chance to explore alongside
a small group of colleagues.</Typography>

<Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginTop: '48px'}}>Industry Symposia</Typography>
            <Typography sx={{fontSize: '20px', marginTop: '20px'}}>Join industry sessions hosted by leading medical device and pharmaceutical companies to gain deep
insights into advancements, innovations, and progress in the fields of neuroscience and psychiatry.
</Typography>
<Typography sx={{fontSize: '20px', marginTop: '20px'}}>These sessions will be conducted over the course of the congress, offering delegates the opportunity
to attend alongside the scientific program. All industry sessions will be livestreamed for remote
access.
</Typography>
        </div>
    )
}

export default IndustryExhibitionComp;