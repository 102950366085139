import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import AnimatedNumbers from "react-animated-numbers";
import { useState } from 'react';

interface Metric {
  metric: string;
  value: number;
}

export default function StatsCard(): React.ReactElement {

  const [value1, setValue1] = useState(2648);
  const [value2, setValue2] = useState(98);
  const [value3, setValue3] = useState(297);
  const [value4, setValue4] = useState(328);

  const metrics: Metric[] = [
    { metric: 'Participants', value: value1 },
    { metric: 'Educational Sessions', value: value2 },
    { metric: 'Speakers & Instructors', value: value3 },
    { metric: 'Presentations', value: value4 },
  ];

  const [num, setNum] = React.useState(331231);

  return (
    <div>
        <Box sx={{margin: '100px 20px 100px 20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box
                sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: 'fit-content',
                margin: 'auto',
                boxShadow: 4, // Adjust shadow intensity as needed
                borderRadius: 8, // Add border radius for a softer look
                p: 3, // Add padding for better separation from the box
                justifyContent: 'center'
                }}
            >
                {metrics.map((metric) => (
                <MetricCard key={metric.metric} metric={metric.metric} value={metric.value} />
                ))}
            </Box>
        </Box>
        <Box sx={{ flexGrow: 1, margin: '100px auto 0 auto', padding: '0 20px', maxWidth: '1200px' }}>
            <Grid container>
                <Grid xs={12}>
                    <Typography sx={{fontSize: '40px', color: '#174B58', fontFamily: 'Big Shoulders Display Black'}}>About Us</Typography>
                    <Typography sx={{color: '#28272C', fontSize: '20px'}}>
                        The Neuroscience & Psychiatry 2024 congress is a major event for professionals in the fields of neuroscience and psychiatry worldwide. It serves as a key platform for education, knowledge exchange, and innovation, bringing together experts to explore advancements in these disciplines and related areas such as neurology. The event, held in Dubai, offers both in-person and virtual participation options to ensure accessibility for all attendees, with a focus on facilitating engagement and collaboration within the global professional community.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
      <div>
    </div>
    </div>
  );
}

function MetricCard({ metric, value }: Metric): React.ReactElement {
  const numDigits = value.toString().length;
  const digitWidth = numDigits > 4 ? numDigits * 10 : 42;

  const plusSymbolStyle = {
    marginLeft: `${digitWidth * 0.1}px`, // Adjust the distance between number and plus symbol
    //fontSize: `${digitWidth * 0.5}px`, // Adjust the size of the plus symbol
    verticalAlign: "middle",
    fontWeight: 'bold', 
    fontSize: '62px',
    color:'#174B58'
  };

  return (
    <Box sx={{ display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "250px",
    margin: "10px",
    textAlign: "center", }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <AnimatedNumbers
          transitions={(index) => ({
            type: "spring",
            duration: index + 0.3,
          })}
          animateToNumber={value}
          fontStyle={{
            fontSize: '62px', fontWeight: 'bold', color:'#174B58', width: `${digitWidth}px`
          }}
        />
      </div>
      {/* <AnimatedNumbers
            transitions={(index) => ({
              type: 'spring',
              duration: index + 0.3,
            })}
            animateToNumber={value}
            fontStyle={{
              fontSize: '62px', fontWeight: 'bold', color:'#174B58', width: `${digitWidth}px`
        }}>
        </AnimatedNumbers> */}
      <Typography variant="body1" component="div" sx={{ fontSize: '15' }}>
        {metric}
      </Typography>
    </Box>
  );
}
