import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";
import { TimelineConnector, TimelineSeparator } from "@mui/lab";
import announcement from "../Images/announcement.png";
import announcement2 from "../Images/announcement2.png";
import earlybird from "../Images/earlybird.png";
import registration from "../Images/registration.png";
import deadline from "../Images/deadline.png";
import notification from "../Images/notification.png";
import deadline2 from "../Images/deadline2.png";

const ImportantDatesAlternate = () => {

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        };
        
        const isMobile = useMediaQuery('(max-width:1035px)');

    return (
        <div style={{margin: isMobile? '30px 7% 80px 7%' : '30px auto 80px auto', maxWidth: '1000px'}}>
      <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginBottom: '30px'}}>Important Dates</Typography>
      {
        data.map((item, index) => (
          <div style={{marginBottom: '32px'}}> 
            {
              isMobile ? (
                <Accordion sx={{
                  padding: isMobile? '10px 30px 10px 30px' : '40px 30px 40px 30px',
                  border: expanded === `panel${index + 1}` ? '2px solid #174B58' : '1px solid #CCCCCC',
                  borderRadius: '18px !important', 
                  overflow: 'hidden',
                  boxShadow: expanded === `panel${index + 1}` ? '6px 6px 8px rgba(0, 0, 0, 0.1)' : 'none'
                }}  expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{borderRadius: '50%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', width: '40px', height: '40px', color: expanded === `panel${index + 1}` ? '#FFFFFF' : '#174B58',  backgroundColor: expanded === `panel${index + 1}` ? '#174B58' : '#ffffff'}} />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{height: isMobile? 'auto' : '2rem',}}>
                          <Grid container md={12} style={{width: '100%',  alignItems: 'center'}}>
                              <Grid item xs={12} md={5} style={{display: 'flex', flexDirection: 'row', alignSelf: isMobile? 'center' : ''}}>
                                  <Typography variant="h6" sx={{ marginLeft: '20px', fontSize: '80px', fontFamily: 'Inter Bold', WebkitTextStroke: '2px #174B58', color: 'transparent' }}>
                                      {item.date}
                                  </Typography>
                                  <Typography variant="h6" sx={{display: 'flex', flexDirection: 'column', alignSelf: 'center' , marginLeft: '24px', marginRight: '24px', fontSize: '20px', color:'#174B58', fontWeight: 'bold' }}>
                                      {item.month}
                                      <Typography variant="h6" sx={{ marginLeft: '0', color:'#415162', fontSize: '15px' }}>
                                      {item.day}
                                      </Typography>
                                  </Typography>
                              </Grid>
                          </Grid>           
                  </AccordionSummary>
                  <AccordionDetails sx={{paddingRight: '20%', paddingTop: isMobile? '10px' : '40px'}}>
                    <Grid container xs={12}>
                      <Grid item xs={3}>
                          <img width={40} height={40} src={item.img}/>
                      </Grid>
                      <Grid item xs={9} style={{  }}>
                          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                              <Typography variant="body1" sx={{paddingBottom: '5px', fontSize: '20px', color:'#174B58', fontWeight: 'bold'}}>
                              {item.topic}
                              </Typography>
                              <Typography variant="body1" sx={{color: '#415162'}}>
                              {item.sub_topic}
                              </Typography>
                          </div>
                      </Grid>
                      <Grid item md={12}>
                          <TimelineSeparator sx={{height: isMobile? '2px' : '80px', margin: '20px 0px 20px 0px' }}>
                              <TimelineConnector sx={{width: isMobile? '60vw' : '2px'}}/>
                          </TimelineSeparator>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {item.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
              </Accordion>
              ) : (
                <Accordion sx={{
                  padding: '40px 30px 40px 30px',
                  border: expanded === `panel${index + 1}` ? '2px solid #174B58' : '1px solid #CCCCCC',
                  borderRadius: '18px !important', 
                  overflow: 'hidden',
                  boxShadow: expanded === `panel${index + 1}` ? '6px 6px 8px rgba(0, 0, 0, 0.1)' : 'none'
                }}  expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{borderRadius: '50%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', width: '40px', height: '40px', color: expanded === `panel${index + 1}` ? '#FFFFFF' : '#174B58',  backgroundColor: expanded === `panel${index + 1}` ? '#174B58' : '#ffffff'}} />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{height: isMobile? 'auto' : '2rem',}}>
                          <Grid container md={12} style={{width: '100%',  alignItems: 'center'}}>
                              <Grid item xs={1} md={1}>
                                  <img width={40} height={40} src={item.img}/>
                              </Grid>
  
                              <Grid item xs={11} md={5} style={{  }}>
                                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                      <Typography variant="body1" sx={{paddingBottom: '5px', fontSize: '20px', color:'#174B58', fontWeight: 'bold'}}>
                                      {item.topic}
                                      </Typography>
                                      <Typography variant="body1" sx={{color: '#415162'}}>
                                      {item.sub_topic}
                                      </Typography>
                                  </div>
                              </Grid>
  
                              <Grid item md={1}>
                                  <TimelineSeparator sx={{height: isMobile? '2px' : '80px', margin: '20px 48px 10px 48px' }}>
                                      <TimelineConnector sx={{width: isMobile? '60vw' : '2px'}}/>
                                  </TimelineSeparator>
                              </Grid>
  
                              <Grid item xs={12} md={5} style={{display: 'flex', flexDirection: 'row', alignSelf: isMobile? 'center' : ''}}>
                                  <Typography variant="h6" sx={{ marginLeft: '20px', fontSize: '80px', fontFamily: 'Inter Bold', WebkitTextStroke: '2px #174B58', color: 'transparent' }}>
                                      {item.date}
                                  </Typography>
                                  <Typography variant="h6" sx={{display: 'flex', flexDirection: 'column', alignSelf: 'center' , marginLeft: '24px', marginRight: '24px', fontSize: '20px', color:'#174B58', fontWeight: 'bold' }}>
                                      {item.month}
                                      <Typography variant="h6" sx={{ marginLeft: '0', color:'#415162', fontSize: '15px' }}>
                                      {item.day}
                                      </Typography>
                                  </Typography>
                              </Grid>
                          </Grid>           
                  </AccordionSummary>
                  <AccordionDetails sx={{paddingRight: '20%', paddingTop: '40px'}}>
                    <Typography>
                      {item.description}
                    </Typography>
                  </AccordionDetails>
              </Accordion>
              )
            }
          </div>
        ))
      }
    </div>
    )
}

const data=[
    {
      topic:'Call for Abstracts Announcement',
      sub_topic: '',
      description:'The official announcement is made, inviting researchers and scholars to submit their abstracts for consideration.',
      date: '01',
      month: 'August 2024',
      day: 'Monday',
      img : announcement2
    },
    {
        topic:'Early Bird Registration Opens',
        sub_topic: '',
        description:'Attendees can start registering early to take advantage of discounted rates, ensuring cost-effective participation.',
        date: '07',
        month: 'August 2024',
        day: 'Tuesday',
        img: earlybird
      },
      {
        topic:'Group Registration Opens',
        sub_topic: '',
        description:'The official announcement is made, inviting researchers and scholars to submit their abstracts for consideration.',
        date: '15',
        month: 'August 2024',
        day: 'Friday',
        img: registration
      },
      {
        topic:'Abstract Submission Deadline',
        sub_topic: '',
        description:'Authors must submit their abstracts for consideration by this date.',
        date: '22',
        month: 'August 2024',
        day: 'Wednesday',
        img: deadline
      },
      {
        topic:'Presenter Notification',
        sub_topic: '',
        description:'Authors will receive notifications regarding the acceptance of their abstracts, allowing them ample time to prepare for their presentations.',
        date: '01',
        month: 'September 2024',
        day: 'Thursday',
        img: notification
      },
      {
        topic:'Regular Registration Deadline',
        sub_topic: '',
        description:'This marks the deadline for regular registration, after which fees may increase, encouraging attendees to register promptly before the conference.',
        date: '05',
        month: 'September 2024',
        day: 'Sunday',
        img: deadline2
      },
]

export default ImportantDatesAlternate;