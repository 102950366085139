import DrawerAppBar from "../../Components/DrawerAppBar"
import ExplorePageContent from "../../Components/ExplorePageContent"
import Footer from "../../Components/Footer"


const ExplorePage = () => {
  return (
    <div>
        <DrawerAppBar/>
        <ExplorePageContent/>
        <Footer/>
    </div>
  )
}

export default ExplorePage