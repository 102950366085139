import AboutUsTwoColumnComp from "../../Components/AboutUsTwoColumnComp"
import DrawerAppBar from "../../Components/DrawerAppBar"
import Footer from "../../Components/Footer"

const AboutUsPage = () => {
    return (
        <div>
            <DrawerAppBar/>
            <AboutUsTwoColumnComp/>
            <Footer/>
        </div>
    )
}

export default AboutUsPage