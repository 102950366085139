import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderBottom: 'none', // Remove the default border
  '&.MuiAccordion-root:before': {
    display: 'none', // Hide the border line
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', fontWeight: 'bold', color: 'black' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  margin: 0,
  padding: 0
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    margin: 0,
}));

export default function ScientificSessionsPageComp() {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

//   const handleChange =
//     (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
//         setExpanded((prevExpanded) => (prevExpanded === panel ? false : panel));
//     };

  return (
        <div style={{margin: '0 auto 80px auto',padding: '0 7% 0 7%',  maxWidth: '1200px'}}>
        <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', paddingTop: '48px'}}>Scientific Sessions</Typography>
        {
            scientific_sessions_data.map((item, index) => (
                <Accordion>
                    <AccordionSummary aria-controls={`pane${index.toString()}d-content`} id={`panel${index.toString()}d-header`}>
                    <Typography sx={{fontWeight: 'bold', fontSize: '19px'}}>{item.session_title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        <ul style={{marginLeft: '40px'}}>
                            {
                                item.session_sub_category.map((item, index) => (
                                    <li>{item}</li>
                                ))
                            }
                        </ul>
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            ))
        }
    </div>
  );
}

const scientific_sessions_data = [
    {
        "session_title": "Neuroscience and Neurology",
        "session_sub_category": [
            "Neurodegenerative Diseases",
            "Neurodevelopmental Disorders",
            "Neuro-Oncology"
        ]
    },
    {
        "session_title": "Psychiatry",
        "session_sub_category": [
            "Mood Disorders",
            "Anxiety Disorders",
            "Schizophrenia and Psychotic Disorders"
        ]
    },
    {
        "session_title": "Neuroimmunology",
        "session_sub_category": [
            "Autoimmune Neurological Disorders",
            "Neuroinflammatory Diseases"
        ]
    },
    {
        "session_title": "Child and Adolescent Psychiatry",
        "session_sub_category": [
            "Pediatric Mood and Anxiety Disorders",
            "Attention-Deficit/Hyperactivity Disorder (ADHD)",
            "Pediatric Neurodevelopmental Disorders"
        ]
    },
    {
        "session_title": "Psychology and Mental Health",
        "session_sub_category": [
            "Psychotherapy and Counseling",
            "Mental Health Promotion and Prevention"
        ]
    },
    {
        "session_title": "Paediatric Neurology",
        "session_sub_category": [
            "Pediatric Epilepsy",
            "Pediatric Neurogenetics"
        ]
    },
    {
        "session_title": "Adult Neurogenesis and Cell Biology",
        "session_sub_category": [
            "Neurogenesis in Aging",
            "Stem Cell Therapy in Neurological Disorders"
        ]
    },
    {
        "session_title": "Neuroinfections and Neuroinflammation",
        "session_sub_category": [
            "Infectious Diseases of the Nervous System",
            "Inflammatory Neuropathies"
        ]
    },
    {
        "session_title": "Neuropharmacology and Neuroendocrinology",
        "session_sub_category": [
            "Pharmacotherapy in Psychiatry",
            "Neuroendocrine Disorders"
        ]
    },
    {
        "session_title": "Alzheimer's Disease, Aging, and Dementia",
        "session_sub_category": [
            "Early Detection and Prevention Strategies",
            "Caregiving and Support Services"
        ]
    },
    {
        "session_title": "Stroke and Trauma",
        "session_sub_category": [
            "Neurorehabilitation",
            "Traumatic Brain Injury"
        ]
    },
    {
        "session_title": "Neurosurgery",
        "session_sub_category": [
            "Minimally Invasive Neurosurgery",
            "Functional Neurosurgery"
        ]
    },
    {
        "session_title": "Brain and Neurological Disorders",
        "session_sub_category": [
            "Movement Disorders",
            "Sleep Disorders"
        ]
    },
    {
        "session_title": "Neuroimaging and Pathology",
        "session_sub_category": [
            "Advanced Neuroimaging Techniques",
            "Neuropathological Studies"
        ]
    },
    {
        "session_title": "Neurotherapeutics, Diagnostics, and Case Studies",
        "session_sub_category": [
            "Novel Therapeutic Approaches",
            "Diagnostic Advances in Neurology and Psychiatry",
            "Case Presentations and Discussions"
        ]
    },
    {
        "session_title": "Neurological Complications of COVID-19",
        "session_sub_category": [
            "Long COVID Neurological Sequelae",
            "Neurological Manifestations during Acute Infection"
        ]
    }
]
