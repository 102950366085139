import { Typography, useMediaQuery } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MultiCarousel from "./MultiCarousel";
import BasicCard from "./BasicCard";

const Feedbacks = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      const items = [
          <BasicCard 
          feedbackText="Attending NEUROSCIENCE & PSYCHIATRY 2022 was an enlightening experience. The diverse range of topics covered and the caliber of speakers truly enriched my understanding of the field. I'm grateful for the opportunity to exchange ideas and insights with colleagues from around the world." 
          author = 'Enrico Bignetti'
          authorDesignation = 'Parma University, Italy'/>,
          <BasicCard 
          feedbackText='NEUROSCIENCE & PSYCHIATRY 2022 provided a platform for invaluable discussions and collaborations. The presentations were thought-provoking and the networking opportunities were abundant. I look forward to incorporating the knowledge gained into my research and practice.' 
          author = 'Benjamin S Weeks'
          authorDesignation = 'Adelphi University, USA'/>,
          <BasicCard 
          feedbackText='As a researcher in neuroscience, attending NEUROSCIENCE & PSYCHIATRY 2022 was essential for staying updated on the latest advancements in the field. The conference fostered a sense of global cooperation and shared purpose, which is crucial for tackling complex challenges in mental health.' 
          author = 'Hitoshi Sakano'
          authorDesignation = 'University of Fukui, Japan'/>,
          <BasicCard 
          feedbackText='NEUROSCIENCE & PSYCHIATRY 2022 was an enriching experience that broadened my perspective on neurological disorders and psychiatric conditions. The opportunity to learn from experts and engage in fruitful discussions has inspired me to continue my efforts in improving patient care and advancing research in this field.' 
          author = 'Siva Anoop Yella'
          authorDesignation = 'Telangana Institute of Medical Sciences, India'/>,
          <BasicCard 
          feedbackText='Attending NEUROSCIENCE & PSYCHIATRY 2022 was a truly rewarding experience. The insights gained from the sessions and interactions with fellow professionals have deepened my understanding of the intricate connections between neuroscience and psychiatry. I am grateful for the opportunity to be part of such a dynamic and informative conference.' 
          author = 'Ashis Pathak'
          authorDesignation = 'Fortis Hospital, India'/>,
          <BasicCard 
          feedbackText='NEUROSCIENCE & PSYCHIATRY 2022 provided a comprehensive overview of the latest developments in the field, from cutting-edge research to innovative clinical practices. The exchange of knowledge and expertise among attendees was invaluable, and I am confident that the insights gained will positively impact my work.' 
          author = 'Ekaterina Shamina'
          authorDesignation = 'Military Medical Academy, Russian Federation'/>,
          <BasicCard 
          feedbackText='As a researcher dedicated to understanding the complexities of developmental biology, NEUROSCIENCE & PSYCHIATRY 2022 offered a unique opportunity to explore the intersection between neuroscience and psychiatry. The interdisciplinary approach taken at the conference has inspired new avenues for exploration in my research.' 
          author = 'Michael V Ugrumov'
          authorDesignation = 'Koltzov Institute of Developmental Biology RAS, Russian Federation'/>,
          <BasicCard 
          feedbackText='NEUROSCIENCE & PSYCHIATRY 2022 was a thought-provoking and enlightening experience. The presentations and discussions were insightful, and I appreciated the opportunity to learn from experts in the field. I look forward to integrating this newfound knowledge into my practice to better serve my patients.' 
          author = 'Ruth Helena Lueg'
          authorDesignation = 'Clinical psychotherapist, Germany'/>,
          <BasicCard 
          feedbackText='Attending NEUROSCIENCE & PSYCHIATRY 2022 was an enriching experience that allowed me to deepen my understanding of the complex interplay between neuroscience and psychiatry. The diversity of perspectives presented at the conference provided valuable insights that will inform my future research and clinical practice.' 
          author = 'Luz Navarro'
          authorDesignation = 'National Autonomous University of Mexico, Mexico'/>,
          <BasicCard 
          feedbackText='NEUROSCIENCE & PSYCHIATRY 2022 was an exceptional gathering of minds dedicated to advancing our understanding of the brain and mental health. The opportunity to engage with experts from around the world has broadened my perspective and inspired me to push the boundaries of my own research.' 
          author = 'Noury Bakrim'
          authorDesignation = 'University of the People, USA'/>,
        // Add more items as needed
      ];

    return (
        <div style={{backgroundColor: '#18424C'}}>
          <div style={{paddingBottom: '70px', maxWidth: '1300px', margin: 'auto'}}>
            <div style={{margin: '100px 100px 0px 100px'}}>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '100px 0 60px 0'}}>
                    <Typography sx={{fontSize: '40px',fontFamily: 'Big Shoulders Display Black', color: '#FFFFFF'}}>
                        Feedbacks
                    </Typography>
                    <Typography sx={{color: '#FFFFFF', fontSize: '15px'}}>
                        At NSSA, we value the opinions and experiences of our attendees. Here's what some of our past participants had to say about their conference experience:
                    </Typography>
                </div>
             </div>
            <MultiCarousel items={items} page="feedback"/>
          </div>
        </div>
    )
}

export default Feedbacks;