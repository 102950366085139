import { Card, CardContent, Typography } from "@mui/material";
import MultiCarousel from "./MultiCarousel";
import venueImg1 from "../Images/venueImg1.svg";
import venueImg2 from "../Images/venueImg2.svg";
import venueImg3 from "../Images/venueImg3.svg";


const carouselItems = [

    <div style={{margin: '0 20px', overflow: 'hidden', padding: '2rem 0'}}>
        <img width='100%' style={{borderRadius: '10px'}} src={venueImg1}/>
    </div>,
    <div style={{margin: '0 20px', overflow: 'hidden', padding: '2rem 0'}}>
        <img width='100%' style={{borderRadius: '10px'}} src={venueImg2}/>
    </div>,
    <div style={{margin: '0 20px', overflow: 'hidden', padding: '2rem 0'}}>
        <img width='100%' style={{borderRadius: '10px'}} src={venueImg1}/>
    </div>,
    <div style={{margin: '0 20px', overflow: 'hidden', padding: '2rem 0'}}>
        <img width='100%' style={{borderRadius: '10px'}} src={venueImg2}/>
    </div>,
    <div style={{margin: '0 20px', overflow: 'hidden', padding: '2rem 0'}}>
        <img width='100%' style={{borderRadius: '10px'}} src={venueImg1}/>
    </div>,
    <div style={{margin: '0 20px', overflow: 'hidden', padding: '2rem 0'}}>
        <img width='100%' style={{borderRadius: '10px'}} src={venueImg2}/>
    </div>,
    
]

const VenueComp = () => {
    return (
        <div style={{margin: '30px 7% 80px 7%'}}>
            <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginTop: '48px'}}>Venue</Typography>
            <Typography sx={{fontSize: '20px', marginTop: '20px'}}>Dubai, the vibrant city of the United Arab Emirates, pulsates with energy, seamlessly weaving
together a rich tapestry of cultures and lifestyles. Here, towering skyscrapers coexist harmoniously
with ancient traditions, creating a captivating urban landscape. From bustling souks to sleek
shopping malls, Dubai offers a kaleidoscope of experiences for visitors to explore.
            </Typography>
            <Typography sx={{fontSize: '20px', marginTop: '20px'}}>Radisson Blu Hotel, Dubai Media City, nestled in the heart of this dynamic city, provides a luxurious
oasis for guests to retreat to after a day of exploration. With its modern amenities and world-class
service, the hotel offers a perfect blend of comfort and sophistication.
            </Typography>
            <Typography sx={{fontSize: '20px', marginTop: '20px'}}>Dubai, a hub of international tourism, attracts visitors from around the globe, drawn to its vibrant
atmosphere and iconic landmarks. From the bustling streets of the city to the serene shores of the
Arabian Gulf, Dubai offers a wealth of experiences for travelers to discover.
            </Typography>
            <Typography sx={{fontSize: '20px', marginTop: '20px'}}>The city experiences a desert climate, with temperatures ranging from mild to hot throughout the
year. Whether you’re seeking relaxation or adventure, Dubai promises an unforgettable experience
for all who visit.
            </Typography>
            <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginTop: '48px'}}>Images</Typography>
            <MultiCarousel items={carouselItems} page="venue"/>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2532.7087888133588!2d55.15241736095047!3d25.094010044049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b43152a323d%3A0xd8f86d46ee00d67c!2sRadisson%20Blu%20Hotel%2C%20Dubai%20Media%20City!5e0!3m2!1sen!2sin!4v1714550306516!5m2!1sen!2sin" style={{border: '0', marginTop: '48px', borderRadius: '20px', width: '100%', height: '428px'}} loading="lazy"></iframe>
        </div>
    )
}

export default VenueComp;