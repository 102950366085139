import DrawerAppBar from "../../Components/DrawerAppBar";
import Footer from "../../Components/Footer";
import TimerCard from "../../Components/TimerCard";
import VenueComp from "../../Components/VenueComp";

const VenuePage = () => {
    return (
        <div>
            <DrawerAppBar/>
            <VenueComp/>
            <TimerCard/>
            <Footer/>
        </div>
    )
}

export default VenuePage;