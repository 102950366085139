import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css";
import { Fab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import  './TitleTimer.css'
import './GlobalStyling.css'
import dubaiImg from "../Images/dubaicropped.svg";
import { useNavigate } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function TitleTimer() {
    const handleClick = () => {
        console.info('You clicked the Chip.');
      };

      const navigate = useNavigate()

      const handleNavigate = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigate("/explorenow")
      };

      const [days, setDays] = useState(0);
      const [hours, setHours] = useState(0);
      const [minutes, setMinutes] = useState(0);
      const [seconds, setSeconds] = useState(0);

      const deadline = "September, 11, 2024";

      const getTime = () => {
        const time = Date.parse(deadline) - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
      }

      useEffect(() => {
        const interval = setInterval(() => getTime(), 1000);
        return () => clearInterval(interval);
      }, []);

      const isMiniMobile = useMediaQuery('(max-width:375px)');
      const isMobile = useMediaQuery('(max-width:1035px)');
      const isSmallDisplay = useMediaQuery('(max-width:1189px');
      const isSmallHeight = useMediaQuery('(max-height:730px');

      const [imageHeight, setImageHeight] = useState(0);

      useEffect(() => {
          const img = new Image();
          img.src = dubaiImg;
          img.onload = () => {
              setImageHeight(img.height);
          };
      }, []);
    
  return (
    <div style={{background: 'linear-gradient(to bottom, #06242B, #174B58)', paddingTop: '50px', position: 'relative', paddingBottom: '50px',overflowX: isMobile ? 'hidden' : 'hidden', overflowY: 'hidden', minHeight: '90vh'}}>
      <div style={{ maxWidth: '1200px', margin: 'auto',padding:'0 10px'}}>
         <Grid container rowSpacing={2} columns={{ xs: 12 }} sx={{position: 'relative', zIndex: 3}} alignItems='end' justifyContent="space-between">
            <Grid xs={12} md={6} sx={{ marginTop: '0' }}>
                  <div style={{ marginLeft: isMobile ? '0px' : '100px', marginRight: isMobile ? 0 : '100px' }}>
                      <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: isSmallDisplay? '3em' : '3.5em', color:'#59FFFF'}}>
                          NEUROSCIENCE &
                      </Typography>
                      <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: isSmallDisplay? '3em' : '3.5em', color: '#59FFFF', marginTop: '-10px'}}>
                          PSYCHIATRY 2024
                      </Typography>
                      <Typography sx={{
                          color: '#D9D9D9',
                          fontSize: '18px',
                          display: 'flex',
                          alignItems: 'center',
                      }}>
                          Sept 11-12, 2024
                          <span style={{ margin: '0 5px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10px' }}>•</span> 
                          Dubai UAE
                      </Typography>
                  </div>
                </Grid>
                <Grid xs={12} md={6}>
                  <div style={{ marginRight: isMobile ? 0 : '100px', marginLeft: isMobile ? '0px' : 0 }}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'start' : 'center' }}>
                      <div className="titleTtimer" role="timer" style={{margin: 0, paddingTop: '16px', width: '95%'}}>
                        <div className="titleCol-4">
                          <div className="titleBox">
                            <p id="day" style={{fontFamily: 'Roboto', fontWeight: 'bold'}}>{days < 10 ? "0" + days : days} : </p>
                            <span className="text" style={{fontFamily: 'Roboto', fontSize: '1.3em'}}>Days</span>
                          </div>
                        </div>
                        <div className="titleCol-4">
                            <div className="titleBox" style={{paddingLeft: '0px'}}>
                              <p id="hour" style={{fontFamily: 'Roboto', fontWeight: 'bold'}}>{hours < 10 ? "0" + hours : hours} : </p>
                              <span className="text" style={{fontFamily: 'Roboto', fontSize: '1.3em'}}>Hours</span>
                            </div>
                        </div>
                        <div className="titleCol-4">
                            <div className="titleBox" style={{paddingLeft: '0px'}}>
                              <p id="minute" style={{fontFamily: 'Roboto', fontWeight: 'bold'}}>{minutes < 10 ? "0" + minutes : minutes} : </p>
                              <span className="text" style={{fontFamily: 'Roboto', fontSize: '1.3em'}}>Minutes</span>
                            </div>
                        </div>
                        <div className="titleCol-4">
                            <div className="titleBox" style={{paddingLeft: '10px'}}>
                              <p id="second" style={{fontFamily: 'Roboto', fontWeight: 'bold',}}>{seconds < 10 ? "0" + seconds : seconds}</p>
                              <span className="text" style={{fontFamily: 'Roboto', fontSize: '1.3em'}}>Seconds</span>
                            </div>
                        </div>
                      </div>
                      <Stack direction= {isMobile ? "column" : "row"} spacing={1} sx={{paddingTop: '32px'}}>
                      <Fab variant="extended" 
                      sx={{
                        backgroundColor: '#FFFFFF', 
                        color: '#143F49',
                        textTransform: 'none', 
                        fontSize: '16px', 
                        fontWeight: 'bold', 
                        paddingLeft: '51px', 
                        paddingRight: '51px', 
                        paddingTop: '18px', 
                        paddingBottom: '18px'
                        }}>
                        Register Now
                      </Fab>
                      <Fab 
                      onClick={handleNavigate}
                        variant="extended" 
                        sx={{
                            backgroundColor: 'transparent',
                            color: '#FFFFFF',
                            border: '1px solid #FFFFFF',
                            textTransform: 'none',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            paddingLeft: '51px',
                            paddingRight: '51px',
                            paddingTop: '18px',
                            paddingBottom: '18px',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: '#FFFFFF'
                            },
                            '&:active': {
                                backgroundColor: 'transparent',
                                color: '#FFFFFF'
                            }
                        }}
                      >
                        Explore Now
                      </Fab>
                      </Stack>
                      </div>
                  </div>
                </Grid>
        </Grid>
      </div>
      <div style={{position: 'relative', marginTop: isMobile ? '15vh' : '35vh'}}>
        <div style={{position: 'absolute', bottom: isMobile ? '100%' : '30%', left: '51%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 0 }}>
          <Typography sx={{ fontFamily: 'Big Shoulders Display Black', fontSize: isMobile ? '3.5em' : '10em', letterSpacing: '0.6em', position: 'absolute', top: '0', left: '50%', transform: 'translate(-50%, -90%)', zIndex: 1 }}>
            <span style={{ backgroundImage: 'linear-gradient(to bottom, #00171D, #174B58)', WebkitBackgroundClip: 'text', color: 'transparent' }}>D</span>
            <span style={{ backgroundImage: 'linear-gradient(to bottom, #00171D, #174B58)', WebkitBackgroundClip: 'text', color: 'transparent' }}>U</span>
            <span style={{ backgroundImage: 'linear-gradient(to bottom, #00171D, #174B58)', WebkitBackgroundClip: 'text', color: 'transparent' }}>B</span>
            <span style={{ backgroundImage: 'linear-gradient(to bottom, #00171D, #174B58)', WebkitBackgroundClip: 'text', color: 'transparent' }}>A</span>
            <span style={{ backgroundImage: 'linear-gradient(to bottom, #00171D, #174B58)', WebkitBackgroundClip: 'text', color: 'transparent' }}>I</span>
          </Typography>
          <img style={{ position: 'fixed', top: '50%', left: '50%', transform: isMobile? 'translate(-50%, -50%)' : isSmallHeight? 'translate(-50%, -70%)' : 'translate(-50%, -50%)', zIndex: 1, overflowX: 'hidden', width: isMobile? isMiniMobile ? '120vw' : '300vw' : '130vw', height: isMobile? 'auto' : 'auto' }} src={dubaiImg} />
          {/* <img width={isMobile ? '626.21' : '2500vh'} height={isMobile ? '237.035' : '474.07'} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1, overflowX: 'hidden' }} src={dubaiImg} /> */}
      </div>
    </div>
    </div>
  );
}