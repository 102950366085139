import { Grid, Typography, useMediaQuery } from "@mui/material"
import StarDivider from "./StarDivider";

const AboutUsTwoColumnComp = () => {
    const isMobile = useMediaQuery('(max-width:1035px)');
    return (
        <div style={{margin: '30px auto 0 auto', padding: '0 7% 0 7%',  maxWidth: '1200px'}}>
            <Grid container columns={12}>
                <Grid item sx={{paddingRight: '10%'}} xs={12}>
                    <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginBottom: '16px'}}>About this year's congress</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: isMobile ? '30px' : '0'}}>
                    <Typography sx={{fontSize: '19px'}}>This year’s Neuroscience & Psychiatry 2024 congress stands as a prominent gathering for professionals in the fields of neuroscience and psychiatry worldwide. As one of the largest and most influential scientific congresses in these disciplines, our event serves as a focal point for education, knowledge exchange, and innovation. It provides a contemporary platform for exploring advancements in neuroscience, psychiatry, neurology, and related areas, fostering international collaboration and offering significant visibility for cutting-edge research.</Typography>
                </Grid>
                <Grid xs={12} md={6} sx={{paddingLeft: isMobile ? '0' : '3%'}}>
                    <Typography sx={{fontSize: '19px'}}>Whether you choose to join us in person in Dubai or participate virtually from the convenience of your home or office, Neuroscience & Psychiatry 2024 ensures accessibility and inclusivity for all attendees. Regardless of your location or preferences, we are dedicated to providing a seamless experience tailored to meet your needs and facilitate engagement with our diverse community of professionals.</Typography>
                </Grid>
                <Grid xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                    <StarDivider/>
                </Grid>
            </Grid>
            <Grid container columns={12}>
                <Grid item sx={{paddingRight: '10%'}} xs={12}>
                    <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginBottom: '16px'}}>CME Credits</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: isMobile ? '30px' : '0'}}>
                    <Typography sx={{fontSize: '19px'}}>
                        Neuroscience & Psychiatry 2024 delegates will have the opportunity to claim Continuing Medical
                        Education (CME) credits for both in-person and online sessions, whether attended live or accessed
                        OnDemand, for up to three months following the congress.

                                    Accredited CME is vital for ongoing professional development in the medical field, serving as a
                        testament to the high-quality scientific content of the congress and allowing delegates to accrue
                        necessary CME credits.
                    </Typography>
                </Grid>
                <Grid xs={12} md={6} sx={{paddingLeft: isMobile ? '0' : '3%'}}>
                    <Typography sx={{fontSize: '19px'}}>
                                For Neuroscience & Psychiatry 2024, delegates can earn a total of 22 CME credits, with the
        breakdown as follows: <br/>
                <ul style={{margin: '2% 5%'}}>
                    <li>07 CME Credits for Day 1 (June 27, 2024)</li>
                    <li>08 CME Credits for Day 2 (June 28, 2024)</li>
                    <li>07 CME Credits for Day 3 (June 29, 2024)</li>
                </ul>
                

                    Delegates have the option to convert their European Accreditation Council for Continuing Medical
        Education (EACCME) credits into National CME credits in their respective countries. 

                    Furthermore, physicians can convert their EACCME credits into AMA PRA Category 1 Credit by
        applying to the American Medical Association through www.ama-assn.org/go/cme.
                    </Typography>
                </Grid>
                <Grid xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                    <StarDivider/>
                </Grid>
            </Grid>
        </div>
        
    )
}

export default AboutUsTwoColumnComp;