import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

interface BasicCardProps {
  feedbackText: string;
  author: string;
  authorDesignation: string;
}

const BasicCard : React.FC<BasicCardProps> = ({ feedbackText, author, authorDesignation }) => {
  const isMobile = useMediaQuery('(max-width:1035px)');
  return (
    <Card sx={{ maxWidth: '400px', marginLeft: '20px', borderRadius:'20px' }}>
      <CardContent sx={{padding: '40px 30px 40px 30px', height: '25em', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <Typography sx={{ fontSize: "16px", textAlign:'start', color:'#565656'}}>
          {feedbackText}
        </Typography>
        <div>
          <Typography sx={{marginTop:'0px',marginBottom:'7px', fontSize: "16px", color:'#174B58', fontWeight:'bold', display: 'flex', alignItems:'flex-start' }} color="text.secondary">
            {author}       
          </Typography>
          <Typography sx={{fontSize: '14px', textAlign: 'start', color:'#565656'}}>
            {authorDesignation}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}

export default BasicCard;