import { Grid, Typography, useMediaQuery } from "@mui/material";
import StarDivider from "./StarDivider";
import accomImg from "../Images/accomodationimg.svg";
import dubaiImg from "../Images/dubaiImg.svg"
import TimerCard from "./TimerCard";

const AccomodationComp = () => {
    const isMobile = useMediaQuery('(max-width:1035px)');
    return(
        <>
            <div style={{margin: '30px 7% 30px 7%'}}>
                <Grid container columns={12}>
                    <Grid item xs={12} md={6} sx={{paddingRight: '3%'}}>
                        <img style={{borderRadius: '20px', width: '100%', maxWidth: '100%'}} src={accomImg}/>
                    </Grid>
                    <Grid xs={12} md={6} sx={{paddingLeft: '3%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Typography sx={{fontFamily: 'Big Shoulders Display Black', color: '#174B58', fontSize: '48px'}}>Accomodation</Typography>
                        <Typography sx={{fontSize: '19px'}}>Dubai, located in the United Arab Emirates, is renowned for its blend of modernity and cultural heritage, making it a captivating destination for visitors. As the host city for Neuroscience & Psychiatry 2024, Dubai promises an unforgettable experience for attendees.</Typography>
                    </Grid>
                </Grid>
            </div>
            <div style={{margin: '30px 0 30px 7%'}}>
                <Grid container columns={12}>
                        <Grid item xs={12} md={6} sx={{paddingRight: '3%', marginTop: '48px'}}>
                            <Typography sx={{fontFamily: 'Big Shoulders Display Black',marginBottom: isMobile ? '30px' : '0', fontSize: isMobile ? 'calc(5vw + 30px)' : '86px', textTransform: 'uppercase', color: '#174B58'}}>Neuroscience & Psychiatry 2024 <span style={{WebkitTextStroke: '2px #174B58', color: 'transparent'}}>at dubai</span> </Typography>
                        </Grid>
                        <Grid xs={12} md={6} sx={{paddingLeft: '3%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <img style={{width: '100%', maxWidth: '100%', marginRight: '-100px'}} src={dubaiImg}/>
                        </Grid>
                </Grid>
            </div>
            <div style={{margin: '30px 7% 80px 7%'}}>
                <Grid container columns={12}>
                    <Grid xs={12} sx={{marginTop: '48px'}}>
                        <Typography sx={{fontSize: '20px'}}>
                            We invite you to indulge in the Congress & Exhibition with us in Dubai. To ensure your comfort
                            during your stay, we have handpicked the finest hotels in the city. These accommodations are
                            strategically located, with many situated in the vibrant City Center and conveniently connected to
                            the congress venue via public transportation. For those preferring proximity to the event venue at
                            the Dubai World Trade Centre, we offer a selection of hotels within walking distance.
                        </Typography>
                        <Typography sx={{marginTop: '20px', fontSize: '20px'}}>
                            Rooms will be allocated on a <span style={{textTransform: 'uppercase', color: '#174B58', fontSize: '32px', fontFamily: 'Big Shoulders Display Black'}}>“first come, first served”</span> basis, so we recommend booking early to secure your preferred accommodation. All room rates are listed in local currency, per room per night, inclusive of breakfast, taxes, and service charges.
                        </Typography>
                    </Grid>
                    <Grid xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                        <StarDivider/>
                    </Grid>
                    <Grid xs={12}>
                        <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginBottom: '16px'}}>Fraud Alert</Typography>
                        <Typography sx={{fontSize: '20px'}}>
                            We have become aware of various agencies claiming to offer hotel accommodation on behalf of
                            Neuroscience & Psychiatry 2024, falsely representing our association. Please be advised that these
                            companies are not affiliated with our congress in any capacity. We strongly urge you not to engage
                            with them or book any services they offer. Your safety and security are paramount to us, and we are
                            committed to ensuring a positive and authentic experience for all attendees.
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default AccomodationComp;