import { Grid, Typography, useMediaQuery } from "@mui/material"
import StarDivider from "./StarDivider";

const ExplorePageContent = () => {
    const isMobile = useMediaQuery('(max-width:1035px)');
    return (
        <div style={{margin: '30px auto 0 auto', padding: '0 7% 0 7%',  maxWidth: '1200px'}}>
            <Grid container columns={12}>
                <Grid item sx={{paddingRight: '10%'}} xs={12}>
                    <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginBottom: '16px'}}>Dear Colleagues and Friends,</Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom:'30px'}}>
                    <Typography sx={{fontSize: '19px'}}>It is our pleasure to welcome you to Neuroscience & Psychiatry 2024 (NSP24), the leading congress for neuroscientists and psychiatrists, taking place in the vibrant city of Dubai, UAE.<span style={{fontWeight:'bold'}}> From 25 to 27 May 2024</span>, we will gather to connect, learn, and share our insights in a stimulating and interactive setting.</Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '30px'}}>
                    <Typography sx={{fontSize: '19px'}}>Neuroscience & Psychiatry is an essential event for our community, and this year’s edition is poised to deliver an outstanding experience. Our programme covers a wide range of topics, such as general neuroscience, clinical psychiatry, neurocritical care, and psychopharmacology. Sessions will explore patients’ views, novel therapies, advances in brain-specific interventions, and more.</Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '30px'}}>
                    <Typography sx={{fontSize: '19px'}}>
                    We have designed scientific sessions in various formats that suit different learning styles to facilitate active learning. Moreover, our workshops programme offers a combination of practical training experiences and innovative approaches to medical education. Delegates will be able to develop technical and non-technical skills through hands-on workshops, simulation sessions, and serious games tailored for all levels of expertise.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '30px'}}>
                    <Typography sx={{fontSize: '19px'}}>
                    The NSP Expo returns at Neuroscience & Psychiatry 2024, showcasing the exciting projects and initiatives led by the global neuroscience and psychiatry community. This central room provides an excellent opportunity for delegates to interact with key stakeholders of NP projects and committees. We encourage you to visit this space as it serves as a hub for exchanging knowledge and fostering collaboration within our remarkable community.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '30px'}}>
                    <Typography sx={{fontSize: '19px'}}>
                    As you immerse yourself in the enriching learning and networking opportunities at Neuroscience & Psychiatry 2024 in Dubai, you will also have the chance to discover the dynamic city and its surroundings. Dubai offers a captivating mix of modernity, culture, and natural beauty. There is much to explore, from its renowned architecture to its picturesque parks and gardens.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '30px'}}>
                    <Typography sx={{fontSize: '19px'}}>
                    Dubai, known for its futuristic skyline and rich cultural heritage, is a city of contrasts where traditional markets and contemporary skyscrapers coexist. Its strategic location as a global hub makes it an ideal venue for our international gathering.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '30px'}}>
                    <Typography sx={{fontSize: '19px'}}>
                    Neuroscience & Psychiatry 2024 will serve as an important platform for industry partners to present their latest developments and breakthroughs in our field. This exhibition of cutting-edge innovations will provide valuable insights into emerging trends and technologies that are shaping neuroscience, psychiatry, neurocritical care, psychopharmacology, and mental health.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '30px'}}>
                    <Typography sx={{fontSize: '19px'}}>
                    With a steadfast commitment to advancing our shared understanding of complex issues within our discipline, Neuroscience & Psychiatry 2024 promises to be an unparalleled forum for sharing knowledge and best practices. Our collective efforts during this congress will foster progress and innovation in neuroscience and psychiatry.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '30px'}}>
                    <Typography sx={{fontSize: '19px'}}>
                    We warmly welcome you to Neuroscience & Psychiatry 2024, the premier event for professionals in our field.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '30px'}}>
                    <Typography sx={{fontSize: '17px'}}>
                    Sincerely,
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '0'}}>
                    <Typography sx={{fontSize: '17px'}}>
                    Prof. [Name]  
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '30px'}}>
                    <Typography sx={{fontSize: '17px'}}>
                    President, NSP2024
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '0'}}>
                    <Typography sx={{fontSize: '17px'}}>
                    Prof. [Name]  
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{paddingRight: isMobile ? '0' : '3%', paddingBottom: '30px'}}>
                    <Typography sx={{fontSize: '17px'}}>
                    Scientific Committee Chairperson, NSP2024
                    </Typography>
                </Grid>

            </Grid>
        </div>
        
    )
}

export default ExplorePageContent;