import { useState } from 'react'
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import './Accordion.css'
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery } from '@mui/material';

const Accordionfunction = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
    
    const isMobile = useMediaQuery('(max-width:1035px)');

  return (
    <div style={{margin: '30px 7% 80px 7%'}}>
      <Typography sx={{fontFamily: 'Big Shoulders Display Black', fontSize: '48px', color: '#174B58', marginBottom: '30px'}}>Congress FAQ</Typography>
      {
        data.map((item, index) => (
          <div style={{marginBottom: '32px'}}> 
            <Accordion sx={{
                padding: '40px 30px 40px 30px',
                border: expanded === `panel${index + 1}` ? '2px solid #174B58' : '1px solid #CCCCCC',
                borderRadius: '18px !important', 
                overflow: 'hidden',
                boxShadow: expanded === `panel${index + 1}` ? '6px 6px 8px rgba(0, 0, 0, 0.1)' : 'none'
              }}  expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{borderRadius: '50%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', width: '40px', height: '40px', color: expanded === `panel${index + 1}` ? '#FFFFFF' : '#174B58',  backgroundColor: expanded === `panel${index + 1}` ? '#174B58' : '#ffffff'}} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{paddingRight: '20%'}}>
                <Typography>
                  {item.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))
      }
    </div>
  )
}

const data = [
  {
    question: 'In what format will Neuroscience & Psychiatry 2024 be organized?',
    answer: 'Neuroscience & Psychiatry 2024 will be a hybrid event. The scientific program will take place in person at the Radisson Blu Hotel, Dubai Media City, United Arab Emirates, and will be livestreamed and accessible online for virtual attendees.'
  },
  {
    question: 'When can I register?',
    answer: 'Early registration for both virtual and in-person attendance begins on November 8, 2023, and ends on February 7, 2024.\n\nLate registration for in-person attendance closes on May 20, 2024.\n\nLate registration for virtual attendance closes on May 27, 2024.\n\nCheck out our Important Dates for more information to add to your calendar.'
  },
  {
    question: 'Will I receive CME credits for attending Neuroscience & Psychiatry 2024?',
    answer: 'Yes, delegates of Neuroscience & Psychiatry 2024 will be eligible to claim CME credits for sessions attended in person or online, whether live or OnDemand, for up to three months after the congress.\n\nAccredited Continuing Medical Education (CME) is essential for physicians\' professional development. Accreditation signifies the high quality of scientific content presented at the congress and provides delegates with an opportunity to earn CME credits.\n\nNeuroscience & Psychiatry 2024 offers 22 CME credits, with the breakdown as follows:\n\n- 07 CME Credits for Day 1 of Neuroscience & Psychiatry 2024 (June 27, 2024)\n- 08 CME Credits for Day 2 of Neuroscience & Psychiatry 2024 (June 28, 2024)\n- 07 CME Credits for Day 3 of Neuroscience & Psychiatry 2024 (June 29, 2024)\n\nDelegates can convert their European Accreditation Council for Continuing Medical Education (EACCME) credits into National CME credits in their home country.\n\nPhysicians may also convert their EACCME credits to AMA PRA Category 1 Credit by applying to the American Medical Association at www.ama-assn.org/go/cme.'
  },
  {
    question: 'Will I be able to watch OnDemand recordings after the congress has concluded?',
    answer: 'Yes, scientific sessions will be available OnDemand to registered delegates shortly after they are broadcasted, allowing for review at your convenience.'
  },
  {
    question: 'What is included in my registration fee for the in-person conference?',
    answer: 'Your registration fee for the in-person Neuroscience & Psychiatry 2024 conference includes access to all scientific sessions, e-Poster sessions, and on-demand recordings. You\'ll also have access to Neuroscience & Psychiatry 2024 Expo sessions, Exhibition, and Industry sessions, with the ability to claim CME credits. Additionally, you can register for workshops, simulation sessions, and serious games. Connect with peers and experts in the field in person for a rich networking experience.'
  },
  {
    question: 'What is the program for the in-person conference?',
    answer: 'In-person delegates will have access to the full scientific program and the opportunity to attend workshops (upon pre-registration). More details will be provided soon.'
  },
  {
    question: 'Can I cancel my in-person registration?',
    answer: 'Cancellation of in-person registration is subject to the following handling fees:\n\n- A 25% handling fee if cancellation occurs until February 7, 2024 (inclusive).\n- A 50% handling fee if cancellation occurs from February 8, 2024, until May 20, 2024 (inclusive).\n- A 100% handling fee if cancellation occurs as of May 21, 2024. No refunds will be provided.'
  },
  {
    question: 'Can I register onsite at the conference center in Dubai?',
    answer: 'Yes, onsite registration is available for purchase of a daily pass or a 3 full-day pass. However, onsite registration cannot be completed prior to the conference.'
  },
  {
    question: 'What are the available methods of payment for registering onsite in Dubai?',
    answer: 'The only accepted methods of payment onsite are VISA, Mastercard, and cash.'
  },
  {
    question: 'What is included in my registration fee for the virtual conference?',
    answer: 'Your registration fee for the virtual Neuroscience & Psychiatry 2024 conference includes access to livestreamed scientific sessions and on-demand recordings. You\'ll also have access to livestreamed Industry Sessions and their on-demand recordings, with the ability to claim CME credits.'
  },
  {
    question: 'What is the program for the virtual conference?',
    answer: 'All scientific sessions organized onsite in Dubai will be accessible to virtual delegates, except for Neuro Expo sessions, workshops, and e-Poster presentations. More details will be provided soon.'
  },
  {
    question: 'How do I access the virtual conference?',
    answer: 'Prior to the conference, you will receive links and all necessary information on how to access Neuroscience & Psychiatry 2024 online. Further details will be provided closer to the event.'
  },
  {
    question: 'Can I cancel my virtual registration?',
    answer: 'Cancellation of virtual registration is subject to the following handling fees:\n\n- A 25% handling fee if cancellation occurs until February 7, 2024 (inclusive).\n- A 100% handling fee if cancellation occurs from February 8, 2024, until May 27, 2024 (inclusive). No refunds will be provided.'
  }
];

export default Accordionfunction
