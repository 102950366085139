import CompaniesAndSocietyComp from "../../Components/CompaniesAndSocietyComp";
import DrawerAppBar from "../../Components/DrawerAppBar";
import Footer from "../../Components/Footer";
import TimerCard from "../../Components/TimerCard";

const CompaniesAndSocietyPage = () => {
    return (
        <div>
            <DrawerAppBar/>
            <CompaniesAndSocietyComp/>
            <TimerCard/>
            <Footer/>
        </div>
    )
}

export default CompaniesAndSocietyPage;