import AbstractUploadForm from "../../Components/AbstractUploadForm";
import DrawerAppBar from "../../Components/DrawerAppBar";
import Footer from "../../Components/Footer";
import RegistrationStepper from "../../Components/RegistrationStepper";
import TimerCard from "../../Components/TimerCard";

const RegistrationPage = () => {
    return (
        <div>
            <DrawerAppBar/>
            <RegistrationStepper/>
            <Footer/>
        </div>
    )
}

export default RegistrationPage;